import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'

export default function contactBookDBModule (db) {
  db.version(1).stores({
    contactBooks: '++id'
  })

  return {
    insert: (data) => {
      db.contactBooks.add(data)
    },
    insertMany: (data) => {
      data.forEach(d => {
        db.contactBooks.add(d)
      })
    },
    get: (filter) => {
      return useObservable(
        liveQuery(async () => {
          return await db.contactBooks.toArray()
        })
      )
    },
    delete: (id) => {
      db.contactBooks.delete(id)
    },
    edit: (id, data) => {
      db.contactBooks.update(id, data)
    },
    clear: () => {
      db.contactBooks.clear()
    }
  }
}
