import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'

export default class CallHistoryTransformer {
  event;
  reason;
  saveCall = false;
  dataObj = {}
  locationObj = {}
  adrObj = {}
  constructor (event) {
    this.event = event
    this.getReason()
  }

  collectData () {
    if (this.saveCall) {
      this.setRequestData()
      this.getLocations()
      this.getAdr()
      return { ...this.dataObj, ...this.locationObj, ...this.adrObj }
    }
  }

  getReason () {
    this.reason = this.event.eventData.message?.headers?.Reason?.[0].raw.match(/text="(.*?)"/)
    if (!this.reason || (this.reason[1] && this.reason[1] !== 'Call completed elsewhere')) {
      this.saveCall = true
    }
  }

  setRequestData () {
    this.dataObj = {
      call_id: this.event.session?._request?.call_id,
      call_from: (this.event.session?.direction === 'incoming') ? this.event.session?._request?.from?._uri?._user : this.event.session?._request?.to?._uri?._user,
      call_direction: this.event.session?.direction,
      invite_message: this.event.session?._request?.toString(),
      hangup_cause: this.reason?.[1] || null
    }
  }

  getLocations () {
    if (this.event.session?._request?.headers?.Geolocation) {
      this.locationObj = new EmergencyCallDataParser().parseGeoLocation(this.event.session._request.headers.Geolocation)
    }
  }

  getAdr () {
    if (this.event.session?._request?.headers?.['Call-Info']) {
      this.adrObj = new EmergencyCallDataParser().parseAdr(this.event.session._request.headers['Call-Info'])
    }
  }
}
