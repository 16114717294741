import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@/sass/app.scss'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        footer: '#EFF3F4',
        background: '#DCE1E6',
        primary: colors.blue,
        secondary: colors.indigo,
        accent: colors.yellow.darken2,
        error: colors.red,
        success: colors.green,
        grey: colors.grey.darken3,
        grey_light: colors.grey.lighten1,
        primary_card: '#F7FBFE',
        secondary_card: '#F7FBFE',
        passive_button: '#282B39'
      },
      dark: {
        footer: '#3F4247',
        background: '#2F3446',
        primary: colors.blue,
        secondary: colors.indigo,
        accent: colors.yellow.darken1,
        error: colors.red.darken2,
        success: colors.green,
        grey: colors.grey.darken3,
        grey_light: colors.grey.lighten1,
        primary_card: '#212533',
        secondary_card: '#2E3544',
        passive_button: '#282B39'
      }
    }
  }
})
