<template>
  <div>

    <DialogShared v-model="dialog" max-width="1200" scrollable :title="dialogTitle">
      <RoleForm :key="formKey" :entity="entity" @onCancel="dialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <div class="text-right">
      <v-btn @click="onCreate" icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">
              mdi-plus
            </v-icon>
          </template>
          <span> {{ $t('add_new')}}</span>
        </v-tooltip>
      </v-btn>
    </div>

    <v-data-table class="transparent-background"  :dark="$vuetify.theme.dark"
                  :items="roles"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalRoles"

        :headers="headers"
        :footer-props="{itemsPerPageOptions : [5,10,15]}"
    >

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="mr-1" color="green" small @click.stop="onEdit(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span> {{ $t('edit')}}</span>
          </v-tooltip>
        </v-btn>

        <v-btn class="mr-1" color="error" small @click.stop="onDelete(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-delete-outline
              </v-icon>
            </template>
            <span> {{ $t('delete')}}</span>
          </v-tooltip>
        </v-btn>

      </template>

    </v-data-table>

  </div>
</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'
import RoleForm from '@/app/widgets/admin-panel-widget/components/role/RoleFormComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'

export default {

  name: 'LayoutTab',

  components: { DialogShared, RoleForm },
  data: (vm) => ({
    entity: null,
    formKey: 0,
    dialogTitle: vm.$t('create_role'),
    dialog: false,
    totalRoles: 0,
    roles: [],
    options: {
      page: 1,
      itemsPerPage: 10
    },
    loading: false,
    headers: [

      { text: vm.$t('display_name'), value: 'display_name', sortable: false },
      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  watch: {

    options: {
      handler () {
        this.loadRoles()
      },
      deep: true
    }
  },

  mounted () {
    this.loadRoles()
    this.initModule()
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    initModule () {
      apiService.permissions.list().then((response) => {
        this.$store.dispatch('admin/permissions', response.data.data)
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },
    refreshForm () {
      this.formKey += 1
    },

    loadRoles () {
      this.loading = true

      apiService.roles.list({
        page: this.options.page,
        limit: this.options.itemsPerPage
      }).then((result) => {
        this.totalRoles = result.data.meta.total
        this.roles = result.data.data

        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onFormSubmit () {
      this.dialog = false
      this.loadRoles()
    },

    onEdit (item) {
      this.dialogTitle = this.$t('update_role')

      apiService.roles.get(item.id).then((response) => {
        this.refreshForm()
        this.entity = response.data.data

        this.dialog = true
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },
    onCreate () {
      this.dialogTitle = this.$t('create_role')
      this.entity = null
      this.refreshForm()
      this.dialog = true
    },

    onDelete (item) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('role') })).then(confirmed => {
        if (confirmed) {
          apiService.roles.delete(item.id).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('role_deleted')
            })

            this.loadRoles()
          }).catch((error) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message
            })
          })
        }
      })
    }
  }

}
</script>

<style scoped>

</style>
