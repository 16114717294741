export const authUserMutator = async (state, authUser) => {
  state.authUserState = authUser
}
export const hasAnsweredCallMutator = async (state, payload) => {
  state.hasAnsweredCall = payload
}

export const sipMutator = async (state, payload) => {
  state.sipState[payload.type] = payload.data
}

export const callMutator = async (state, payload) => {
  state.callState[payload.type] = payload.data
}

export const serialMutator = async (state, payload) => {
  state.serialDevices = payload
}
