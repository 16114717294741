const textHelper = {

  /**
   * Capitalize
   * @param value
   * @returns {string}
   */
  capitalize (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  },

  /**
   * Substring text if long enough
   * @param value
   * @param start
   * @param end
   * @param add
   * @returns {string|*}
   */
  substringIf (value, start = 0, end = 15, add = '...') {
    if (value && value.length > end) {
      return value.substring(start, end) + add
    }
    return value
  }

}

export default textHelper
