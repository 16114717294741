import Vue from 'vue'
import Vuex from 'vuex'

import global from '@/store/global'
import adr from '@/store/adr'
import held from '@/store/held'
import layout from '@/store/layout'
import callInformation from '@/app/widgets/call-information-widget/store'
import notifications from '@/app/shared/notification/store'
import admin from '@/app/widgets/admin-panel-widget/store'
import callHistory from '@/app/widgets/call-history-widget/store'
import abandonedCalls from '@/app/widgets/abandoned-calls-widget/store'
import settings from '@/app/widgets/settings-widget/store'
import contactBook from '@/app/widgets/contact-book-widget/store'
import audioVolume from '@/app/components/audio-volume/store'
import dialPad from '@/app/widgets/dial-pad-widget/store'
import speedDial from '@/app/widgets/speed-dial-widget/store'
import recorder from '@/app/widgets/live-recording-widget/store'
import callDetails from '@/app/widgets/call-detail-tabs-widget/store'
import logo from '@/app/widgets/logo-widget/store'
import callAgents from '@/app/widgets/agent-widget/store'
import fccMasterRegistry from '@/app/widgets/fcc-master-registry-widget/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    adr,
    held,
    layout,
    callInformation,
    notifications,
    admin,
    callHistory,
    abandonedCalls,
    settings,
    dialPad,
    contactBook,
    audioVolume,
    speedDial,
    recorder,
    callDetails,
    logo,
    callAgents,
    fccMasterRegistry
  },
  strict: process.env.NODE_ENV !== 'production'
})
