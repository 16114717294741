import textHelper from '@/helpers/text.helper'
import timeHelper from '@/helpers/time.helper'
export default {
  install (Vue) {
    Vue.filter('capitalize', (value) => {
      return textHelper.capitalize(value)
    })

    Vue.filter('substringIf', (value, start, end, add) => {
      return textHelper.substringIf(value, start, end, add)
    })

    Vue.filter('dateFormatter', (value) => {
      return timeHelper.dateFormatter(value)
    })
    Vue.filter('convertSecondsToHms', (value) => {
      return timeHelper.convertSecondsToHms(value)
    })
  }
}
