import apiService from '@/modules/api/csp'
import LocalCacher from '@/modules/cache/localCacher'

/**
 * Get speed dial buttons
 * @param commit
 * @param payload
 * @returns {Promise<void>}
 */
export async function getSpeedDialButtonsAction ({ commit, dispatch, state }, payload) {
  const { data } = await apiService.speedDial.get()
  commit('setSpeedDialContactMutator', data.data)
  if (state.currentFolder) {
    await dispatch('filterSpeedDialButtonsAction', state.currentFolder)
  } else {
    await dispatch('filterSpeedDialButtonsAction')
  }
}

/**
 * Set current folder in store
 * @param commit
 * @param payload
 * @returns {Promise<void>}
 */
export async function setCurrentFolderAction ({ commit }, payload) {
  commit('setCurrentFolderMutator', payload)
}

/**
 * Filter speed dial buttons by parent speed button
 * @param commit
 * @param state
 * @param speedDial
 * @returns {Promise<void>}
 */
export async function filterSpeedDialButtonsAction ({ commit, state }, speedDial) {
  if (speedDial) {
    const filtered = state.speedDialContacts.filter((o) => {
      return o.parent_id === speedDial.id
    })

    commit('setFilteredSpeedDialContactsMutator', filtered)
  } else {
    commit('setFilteredSpeedDialContactsMutator', state.speedDialContacts.filter((o) => {
      // eslint-disable-next-line valid-typeof
      return o.parent_id == null
    }))
  }
}

/**
 * Create speed dial button
 * @param context
 * @param dispatch
 * @param payload
 * @returns {Promise<void>}
 */
export async function createSpeedDialButtonAction ({ context, dispatch }, payload) {
  await apiService.speedDial.create(payload)
  await dispatch('getSpeedDialButtonsAction')
}

/**
 * Update contacts in api and reload contacts
 * @param context
 * @param dispatch
 * @param payload
 * @returns {Promise<void>}
 */
export async function updateSpeedDialButton ({ context, dispatch }, payload) {
  await apiService.speedDial.edit(payload.id, payload)
  LocalCacher.indexDB.speedDial.edit(payload.id, payload)
  await dispatch('getSpeedDialButtonsAction')
}

/**
 * Delete chosen contact from api and reload
 * @param context
 * @param dispatch
 * @param payload
 * @returns {Promise<void>}
 */
export async function deleteSpeedDialButtonAction ({ context, dispatch }, payload) {
  LocalCacher.indexDB.speedDial.delete(payload.id)
  await apiService.speedDial.delete(payload.id)
  await dispatch('getSpeedDialButtonsAction')
}

/**
 * Set current contact in context
 * @param state
 * @param commit
 * @param payload
 */
export function setCurrentContactAction ({ state, commit }, payload) {
  commit('setCurrentContact', payload)
}
