import store from '@/store'

export default class Serial {
  _devices = []

  constructor () {
    this.loadDevices().then(() => this.openAll())
  }

  async loadDevices () {
    const devices = await navigator.serial.getPorts()

    this._devices = devices.map(d => {
      return { device: d, isOpen: false, BaudRate: 9600 }
    })

    store.commit('global/serialMutator', [...this._devices])
  }

  async requestPair () {
    await navigator.serial.requestPort()

    await this.loadDevices()
  }

  openAll () {
    const devices = this.devices.map(d => this.openDevice({ ...d }))

    this._devices = devices

    store.commit('global/serialMutator', [...this._devices])
  }

  openMany (devices) {
    devices = this._devices.map(d => {
      const length = devices.length

      for (let i = 0; i < length; i++) {
        if (this.sameDevice(d, devices[i])) return this.openDevice({ ...d })
      }

      return d
    })

    this._devices = devices

    store.commit('global/serialMutator', [...this._devices])
  }

  openDevice (device) {
    if (device.device.writable) {
      device.isOpen = true

      return device
    }

    const promise = device.device.open({ baudRate: device.BaudRate })

    Promise.resolve(promise)

    device.isOpen = true

    return device
  }

  closeAll () {
    const devices = this.devices.map(d => this.closeDevice({ ...d }))

    this._devices = devices

    store.commit('global/serialMutator', [...this._devices])
  }

  closeMany (devices) {
    devices = this._devices.map(d => {
      const length = devices.length

      for (let i = 0; i < length; i++) {
        if (this.sameDevice(d, devices[i])) return this.closeDevice({ ...d })
      }

      return d
    })

    this._devices = devices

    store.commit('global/serialMutator', [...this._devices])
  }

  closeDevice (device) {
    if (!device.device.writable) {
      device.isOpen = false

      return device
    }

    const promise = device.device.close()

    Promise.resolve(promise)

    device.isOpen = false

    return device
  }

  sameDevice (first, second) {
    return (
      first.device.getInfo().usbProductId === second.device.getInfo().usbProductId &&
      first.device.getInfo().usbProductId === second.device.getInfo().usbProductId
    )
  }

  async toggleOnAll () {
    const data = new Uint8Array([0xA0, 0x01, 0x01, 0xA2]) // Command to light up controller

    this.devices.forEach(async d => {
      if (!d.device.writable) return

      const writer = d.device.writable.getWriter()

      await writer.write(data)

      writer.close()
    })
  }

  async toggleOffAll () {
    const data = new Uint8Array([0xA0, 0x01, 0x00, 0xA1]) // Command to light down controller

    this.devices.forEach(async d => {
      if (!d.device.writable) return

      const writer = d.device.writable.getWriter()

      await writer.write(data)

      writer.close()
    })
  }

  get devices () {
    return this._devices
  }
}
