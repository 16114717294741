export default class EmergencyCallDataParser {
  locationResponse = [];
  adrResponse = {}
  parseGeoLocation (Geolocation = []) {
    Geolocation.forEach(item => {
      this.locationResponse.location_url = this.clearUrl(item.raw)
    })
    return this.locationResponse
  }

  parseAdr (callInfo) {
    callInfo.forEach(item => {
      if (item.raw.endsWith('"EmergencyCallData.Comment"')) {
        this.adrResponse.comment_url = this.clearUrl(item.raw)
      }
      if (item.raw.endsWith('"EmergencyCallData.SubscriberInfo"')) {
        this.adrResponse.subscriber_info_url = this.clearUrl(item.raw)
      }
      if (item.raw.endsWith('"EmergencyCallData.ServiceInfo"')) {
        this.adrResponse.service_info_url = this.clearUrl(item.raw)
      }
      if (item.raw.endsWith('"EmergencyCallData.DeviceInfo"')) {
        this.adrResponse.device_info_url = this.clearUrl(item.raw)
      }
      if (item.raw.endsWith('"EmergencyCallData.ProviderInfo"')) {
        this.adrResponse.provider_info_url = this.clearUrl(item.raw)
      }
    })
    return this.adrResponse
  }

  clearUrl (data) {
    return data.substring(
      data.lastIndexOf('<') + 1,
      data.lastIndexOf('>')
    ).replace(':///', '://')
  }
}
