export default () => ({
  authUserState: null,
  sipState: {
    connecting: false,
    connected: false,
    registering: false,
    registered: false
  },
  callState: {
    canMute: false,
    canLocalHold: false,
    canGlobalHold: false,
    localMuted: false,
    remoteMuted: false,
    localHold: false,
    remoteHold: false,
    calling: false,
    hasActiveCall: false,
    isConference: false,
    isEmergency: false,
    isAdmin: false,
    isMsrp: false,
    isRtt: false,
    transferring: false
  },
  serialDevices: []
})
