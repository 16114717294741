<template>
  <div>

    <DialogShared v-model="dialog" max-width="900" scrollable :title="dialogTitle">
      <RealmSettingsForm :key="formKey" :entity="entity" @onCancel="dialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <v-select v-if="$can('chs_has_access_to_all_realms')" @change="loadSettings"
              :label="$t('realm')"
              v-model="realmName"
              :items="realms"
    ></v-select>

    <v-alert type="info"

             color="red lighten-2"
             dark
    >
      Changes for account <b>{{ realmNameReadOnly }}</b>
    </v-alert>

    <v-alert
      dense
      type="info"
    >
      {{ $t('changes_will_be_applied_after_relogin_or_page_refresh')}}
    </v-alert>

    <v-data-table :disable-pagination="true" :hide-default-footer="true"  class="transparent-background"
                  :items="settings"
                  :loading="loading"

                  :headers="headers"
    >

      <template v-slot:[`item.value`]="{ item }">

        <span v-if="item.type == 'checkbox'">
   <v-switch dense flat inset @change="onItemChange(item)" v-model="item.value.active" :label="item.value.active ? $t('enabled') : $t('disabled')" ></v-switch>

        </span>
        <span v-else>
          Unsupported Type {{ item.type }} with value {{ item.value }}
        </span>

      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-btn :disabled="loading" class="mr-1" color="green" small @click.stop="onEdit(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon smalls v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit')}}</span>
          </v-tooltip>
        </v-btn>

      </template>

    </v-data-table>

  </div>
</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions, mapGetters } from 'vuex'
import RealmSettingsForm from '@/app/widgets/admin-panel-widget/components/settings/RealmSettingsFormComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'

import sipHelper from '@/helpers/sip.helper'

export default {

  name: 'RealmSettingsView',

  components: { RealmSettingsForm, DialogShared },
  data: (vm) => ({

    entity: null,
    formKey: 0,
    dialogTitle: vm.$t('update_realm_setting'),
    dialog: false,
    settings: [],

    realmName: '',
    realmNameReadOnly: '',
    realms: [],
    loading: false,

    headers: [
      { text: vm.$t('value'), value: 'value', sortable: false },
      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('description'), value: 'description', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  async mounted () {
    await this.sipDefaultAddressAction().catch()

    this.realmNameReadOnly = sipHelper.getRealmFromSip(this.sipDefaultAddressGetter.sip_address, this.notificationSnackBarAction)
    if (this.$can('chs_has_access_to_all_realms')) { // set default realm for admin to use
      this.realmName = this.realmNameReadOnly
      await this.loadRealms()
    }

    await this.loadSettings()
  },

  computed: {

    ...mapGetters('settings', [
      'sipDefaultAddressGetter'
    ])

  },

  methods: {
    ...mapActions('settings', [
      'sipAddressesAction',
      'sipDefaultAddressAction'
    ]),
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    refreshForm () {
      this.formKey += 1
    },

    loadRealms () {
      this.loading = true
      apiService.sip.getRealms().then((result) => {
        this.realms = result.filter(n => n != null)
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },
    loadSettings () {
      this.loading = true

      if (this.realmName) { // when admin changes realm - reload read only info
        this.realmNameReadOnly = this.realmName
      }

      apiService.realmSettings.load(this.realmName).then((result) => {
        this.settings = result.data.data

        this.loading = false
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async onItemChange (item) {
      apiService.realmSettings.edit(item.id, item, this.realmName).then((response) => {
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: this.$t('realm_setting_updated')
        })
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onFormSubmit () {
      this.dialog = false
      this.loadSettings()
    },

    onEdit (item) {
      this.dialogTitle = this.$t('update_realm_setting')
      this.refreshForm()
      this.entity = item
      this.dialog = true
    }

  }

}
</script>

<style scoped>

</style>
