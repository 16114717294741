import apiService from '@/modules/api/csp'
import CallHistoryTransformer from '@/modules/api/csp/transformers/call-history.transformer'
import EventDispatcher from '@/modules/event/event-dispatcher.module'
import LocalCacher from '@/modules/cache/localCacher'

export const getUnresolvedAbandonedCallsAction = async ({ commit, dispatch }, payload = {}) => {
  let response = {}

  if (payload.call_from && payload.call_from.length > 0) {
    response = await apiService.abandonedCalls.findUnresolvedAbandonCalls(payload)
  } else {
    response = await apiService.abandonedCalls.getUnresolvedAbandonCalls(payload)
  }

  commit('setAbandonedCallsMutator', response.data)

  return response
}

export const saveAbandonedCallAction = async ({ commit, dispatch }, payload = []) => {
  const collectData = new CallHistoryTransformer(payload).collectData()
  if (collectData) {
    await apiService.abandonedCalls.saveAbandonCall(collectData).then(async () => {
      const event = new EventDispatcher()
      event.dispatch('refreshAbandonCall')
    })
  }
}

export const updateAbandonCallStatusAction = async ({ commit, dispatch }, payload = {}) => {
  LocalCacher.indexDB.abandonedCalls.delete(payload.callId)
  await apiService.abandonedCalls.updateAbandonCallStatus(payload).then(async () => {
    const event = new EventDispatcher()
    event.dispatch('refreshAbandonCall')
  })
}
