<template>
  <v-tooltip bottom :key="buttonKey">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="action_KeyC"
        cypress="connection_proxy"
        v-bind="attrs"
        v-on="on"
        @click.once="toggleConnection"
        :loading="sipGetter.connecting"
        :disabled="!(sipDefaultAddressGetter && settingsProxyGetter.length)"
        icon
        text
        :key="buttonKey">
        <v-icon color="success" v-if="sipGetter.connected">mdi-lan-connect</v-icon>
        <v-icon color="grey_light" v-else>mdi-lan-disconnect</v-icon>
      </v-btn>
    </template>
    <span v-if="sipGetter.connected">{{ $t('connected') }}</span>
    <span v-else>{{ $t('disconnected') }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
/* Mixins */
import clickOnceRefreshMixin from '@/mixins/click-once-refresh.mixin'

export default {
  name: 'ConnectionButton',
  mixins: [clickOnceRefreshMixin],
  data: () => ({
    buttonKey: null
  }),
  computed: {
    ...mapGetters('settings', [
      'sipDefaultAddressGetter',
      'settingsProxyGetter'
    ]),
    ...mapGetters('global', [
      'sipGetter'
    ])
  },
  methods: {
    ...mapMutations('global', [
      'sipMutator'
    ]),
    toggleConnection () {
      if (this.$sip.connected) {
        this.$confirm(this.$t('do_you_want_to_disconnect')).then(confirmed => {
          if (confirmed) this.$sip.disconnect()
        })
      } else {
        this.$sip.connect({
          proxy: this.settingsProxyGetter,
          sipAddress: this.sipDefaultAddressGetter.sip_address,
          password: this.sipDefaultAddressGetter.password,
          displayName: this.sipDefaultAddressGetter.display_name || this.sipDefaultAddressGetter.sip_address,
          register: false,
          remoteAudio: document.getElementById('remoteAudio')
        })
        this.refreshButton()
      }
    }
  },
  mounted () {
    this.buttonKey = this.$uuidKey()
  }
}
</script>

<style scoped>

</style>
