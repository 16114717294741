export default function globalSettingsApiModule (instance) {
  return {

    /**
     * Return list of global settings
     * @param data
     * @returns {Promise<*>}
     */
    load: async (data) => {
      const resource = '/chs/settings/global'

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Update Global Setting
     * @param id
     * @param data
     * @returns {Promise<*>}
     */
    edit: async (id, data) => {
      const resource = `/chs/settings/global/${id}`

      const response = await instance.patch(resource, data)

      return response
    }

  }
}
