<template>

  <validation-observer ref="observer" >

    <v-form class="pt-4" @submit.prevent="onSubmit" novalidate >

      <v-row dense>
        <v-col>
            <validation-provider v-slot="{ errors }" name="name" rules="required">
             <v-text-field outlined name="name" :label="$t('name')" v-model="form.name" :error-messages="errors" ></v-text-field>
           </validation-provider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" name="display_name" rules="required">
           <v-text-field outlined name="display_name" :label="$t('display_name')" v-model="form.display_name" :error-messages="errors"></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <validation-provider v-slot="{ errors }" name="permissions" rules="required">
      <v-row dense>
        <v-col v-if="errors" cols="12">
          <span class="error--text">{{ errors[0] }}</span>
        </v-col>

        <v-col cols="12">
            <v-row dense class="overflow-y-auto pa-0 ma-0 mb-10" style="max-height: 350px">
              <v-col class="pa-0" cols="6" v-for="(p) in permissions" :key="p.name" >
                <v-switch style="font-size: 10px" dense v-model="form.permissions" :label="p.name" :value="p.name"  ></v-switch>
              </v-col>
            </v-row>
        </v-col>
      </v-row>
      </validation-provider>
      <v-row dense>
        <v-col>
          <v-textarea outlined :label="$t('description')" v-model="form.description"></v-textarea>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()">
            {{ $t('cancel')}}
          </v-btn>
          <v-btn :disabled="loading" type="submit" color="red"  >
            {{ $t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

  </validation-observer>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions, mapState } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'RoleFormComponent',

  props: ['entity'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: () => ({
    loading: false,
    form: {
      id: null,
      name: '',
      display_name: '',
      permissions: [],
      description: ''
    }
  }),

  computed: {
    ...mapState({
      permissions: state => state.admin.permissions
    })

  },

  mounted () {
    Object.assign(this.form, this.entity)
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.loading = true

        if (this.form.id) {
          apiService.roles.edit(this.form.id, this.form).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('role_updated')
            })

            this.loading = false

            this.$emit('onSubmit')
          }).catch((error) => {
            this.$refs.observer.setErrors(error.response.data.errors)
            this.loading = false
          })
        } else {
          apiService.roles.create(this.form).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('role_created')
            })
            this.loading = false
            this.$emit('onSubmit')
          }).catch((error) => {
            this.$refs.observer.setErrors(error.response.data.errors)
            this.loading = false
          })
        }
      }
    },
    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>

<style scoped>

</style>
