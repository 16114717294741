<template>

  <validation-observer ref="observer" >

    <v-form class="pt-4" :disabled="loading" @submit.prevent="onSubmit" novalidate >

      <v-row dense>
        <v-col>
          <validation-provider vid="file" :name="$t('file')" rules="required" v-slot="{ errors }">
            <v-file-input
              v-model="form.file"
              truncate-length="15"
              name="file"
              accept=".xlsx,.xls"
              :label="$t('choose_file')"
              show-size
              :error-messages="errors"
            ></v-file-input>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()">
            {{ $t('cancel')}}
          </v-btn>
          <v-btn :disabled="loading" type="submit" color="red"  >
            {{ $t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

  </validation-observer>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'ImportUsersFormComponent',

  props: ['entity'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: () => ({
    loading: false,

    form: {
      file: null
    }
  }),
  methods: {
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.loading = true

        apiService.users.importUsers(this.form).then((response) => {
          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('users_imported_successfully')
          })

          this.onCancel()
          this.$emit('onSubmit')
        }).catch((error) => {
          this.loading = false
          if (error.response.data.message) {
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message
            })
          }

          this.$refs.observer.setErrors(error.response.data.errors)
        })
      }
    },
    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>
