<template>
  <v-card tile flat color="secondary_card" v-if="editedWidget">
    <v-card-text >

      <v-form novalidate @submit.prevent="onSubmit" >

        <v-row>
          <v-col>
            <v-list dense tile flat class="transparent-background" >
              <draggable :empty-insert-threshold="100"  v-model="elements" handle=".handle" group="widgets">
                <transition-group>
                  <v-list-item  class="layout_designer_list my-1"  dense v-for="(element,key) in elements" :key="key">
                    <v-list-item-avatar >
                      <v-icon class="grabbable handle" >
                        mdi-drag
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content >
                      {{ $t(element.key) }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox v-model="element.prop.visible" :label="$t('visible')" />
                    </v-list-item-action>
                  </v-list-item>
                </transition-group>
              </draggable>
            </v-list>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-btn class="mr-5" color="passive_button"   @click="onCancel()">
              {{ $t('cancel')}}
            </v-btn>
            <v-btn type="submit" color="red"  >
              {{ $t('save')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

    </v-card-text>
  </v-card>
</template>

<script>

import draggable from 'vuedraggable'

export default {
  name: 'WidgetFormEmergencyCallTabsComponent',

  components: {
    draggable
  },

  props: ['widget'],
  data: () => ({
    editedWidget: {},
    elements: []
  }),

  beforeMount () {
    this.editedWidget = JSON.parse(JSON.stringify(this.widget))

    // Sort base on prop order.
    this.elements = Object.entries(this.editedWidget.form.tabs)
      .map(([key, prop]) => ({ key, prop }))
      .sort((a, b) => a.prop.order - b.prop.order)
  },
  methods: {
    onCancel () {
      this.$emit('close')
    },
    onSubmit () {
      this.elements.forEach((object, key) => {
        this.editedWidget.form.tabs[object.key].visible = object.prop.visible
        this.editedWidget.form.tabs[object.key].order = key + 1
      })
      this.$emit('submit', this.editedWidget)
    }
  }

}
</script>

<style scoped>

</style>
