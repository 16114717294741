var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"1000"},model:{value:(_vm.userRealmSettingDialog),callback:function ($$v) {_vm.userRealmSettingDialog=$$v},expression:"userRealmSettingDialog"}},[_c('UserRealmSettingsList',{key:'user_realm_'+_vm.formKey,attrs:{"entity":_vm.entity,"realm_name":_vm.realm_name}})],1),_c('v-card',{attrs:{"tile":"","flat":"","color":"secondary_card"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"transparent-background",attrs:{"dark":_vm.$vuetify.theme.dark,"headers":_vm.headers,"items":_vm.realms,"loading":_vm.loading,"items-per-page":10},scopedSlots:_vm._u([{key:"item.realm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.item)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"green","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onAccountSettingsEdit(item.item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('edit')))])])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }