export default function callNotesApiModule (instance) {
  return {
    /**
     * Return list of call notes for phone number
     * @param data
     * @returns {Promise<*>}
     */
    list: async (data) => {
      const resource = `/chs/notes/${data.phone}`
      return await instance.get(resource)
    },

    /**
     * Get single call note
     * @returns {Promise<*>}
     * @param id
     */
    get: async (id) => {
      const resource = `/chs/notes/${id}`
      return await instance.get(resource)
    },

    /**
     * Create call note
     * @returns {Promise<*>}
     * @param data
     */
    create: async (data) => {
      const resource = '/chs/notes'
      return await instance.post(resource, data)
    },

    /**
     * Update call note
     * @returns {Promise<*>}
     * @param id
     * @param data
     */
    edit: async (id, data) => {
      const resource = `/chs/notes/${id}`
      return await instance.patch(resource, data)
    },

    /**
     * Delete call note
     * @returns {Promise<*>}
     * @param id
     */
    delete: async (id) => {
      const resource = `/chs/notes/${id}`
      return await instance.delete(resource)
    }
  }
}
