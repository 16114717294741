export const adrUpdateMutator = async (state) => {
  state.updated = !state.updated
}

export const commentMutator = async (state, payload) => {
  state.commentState.DataProviderReference = payload?.DataProviderReference || 'NA'
  state.commentState.Comment = payload?.Comment || 'NA'
}

export const deviceInfoMutator = async (state, payload) => {
  state.deviceInfoState.DataProviderReference = payload?.DataProviderReference || 'NA'
  state.deviceInfoState.DeviceClassification = payload?.DeviceClassification || 'NA'
  state.deviceInfoState.DeviceMfgr = payload?.DeviceMfgr || 'NA'
  state.deviceInfoState.DeviceModelNr = payload?.DeviceModelNr || 'NA'
  state.deviceInfoState.UniqueDeviceID = payload?.UniqueDeviceID || 'NA'
}

export const providerInfoMutator = async (state, payload) => {
  state.providerInfoState.DataProviderReference = payload?.DataProviderReference || 'NA'
  state.providerInfoState.DataProviderString = payload?.DataProviderString || 'NA'
  state.providerInfoState.ProviderID = payload?.ProviderID || 'NA'
  state.providerInfoState.ProviderIDSeries = payload?.ProviderIDSeries || 'NA'
  state.providerInfoState.TypeOfProvider = payload?.TypeOfProvider || 'NA'
  state.providerInfoState.ContactURI = payload?.ContactURI || 'NA'
  state.providerInfoState.Language = payload?.Language || 'NA'
}

export const serviceInfoMutator = async (state, payload) => {
  state.serviceInfoState.DataProviderReference = payload?.DataProviderReference || 'NA'
  state.serviceInfoState.ServiceEnvironment = payload?.ServiceEnvironment || 'NA'
  state.serviceInfoState.ServiceType = payload?.ServiceType || 'NA'
  state.serviceInfoState.ServiceMobility = payload?.ServiceMobility || 'NA'
}

export const subscriberInfoMutator = async (state, payload) => {
  state.subscriberInfoState.DataProviderReference = payload?.DataProviderReference
  // fn
  state.subscriberInfoState.SubscriberData.vcard.fn.text = payload?.SubscriberData?.vcard?.fn?.text || 'NA'
  // n
  state.subscriberInfoState.SubscriberData.vcard.n.surname = payload?.SubscriberData?.vcard?.n?.surname || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.n.given = payload?.SubscriberData?.vcard?.n?.given || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.n.additional = payload?.SubscriberData?.vcard?.n?.additional || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.n.prefix = payload?.SubscriberData?.vcard?.n?.prefix || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.n.suffix = payload?.SubscriberData?.vcard?.n?.suffix || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.n.suffix = payload?.SubscriberData?.vcard?.n?.suffix || 'NA'
  // lang
  state.subscriberInfoState.SubscriberData.vcard.lang = payload?.SubscriberData?.vcard?.lang || 'NA'
  // adr
  state.subscriberInfoState.SubscriberData.vcard.adr.parameters.type.text = payload?.SubscriberData?.vcard?.adr?.parameters?.type?.text || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.adr.parameters.label.text = payload?.SubscriberData?.vcard?.adr?.parameters?.label?.text || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.adr.pobox = payload?.SubscriberData?.vcard?.adr?.pobox || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.adr.ext = payload?.SubscriberData?.vcard?.adr?.ext || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.adr.street = payload?.SubscriberData?.vcard?.adr?.street || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.adr.locality = payload?.SubscriberData?.vcard?.adr?.locality || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.adr.region = payload?.SubscriberData?.vcard?.adr?.region || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.adr.code = payload?.SubscriberData?.vcard?.adr?.code || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.adr.country = payload?.SubscriberData?.vcard?.adr?.country || 'NA'
  // tel
  state.subscriberInfoState.SubscriberData.vcard.tel.parameters.type = payload?.SubscriberData?.vcard?.tel?.parameters?.type || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.tel.uri = payload?.SubscriberData?.vcard?.tel?.uri || 'NA'
  // email
  state.subscriberInfoState.SubscriberData.vcard.email.parameters.type.text = payload?.SubscriberData?.vcard?.email?.parameters?.type?.text || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.email.text = payload?.SubscriberData?.vcard?.email?.text || 'NA'
  // key
  state.subscriberInfoState.SubscriberData.vcard.key.parameters.type.text = payload?.SubscriberData?.vcard?.key?.parameters?.type?.text || 'NA'
  state.subscriberInfoState.SubscriberData.vcard.key.uri = payload?.SubscriberData?.vcard?.key?.uri || 'NA'
  // tz
  state.subscriberInfoState.SubscriberData.vcard.tz.text = payload?.SubscriberData?.vcard?.tz?.text || 'NA'
}

// Clear ADR

export const clearCommentMutator = async state => {
  state.commentState.DataProviderReference = null
  state.commentState.Comment = null
}

export const clearDeviceInfoMutator = async state => {
  state.deviceInfoState.DataProviderReference = null
  state.deviceInfoState.DeviceClassification = null
  state.deviceInfoState.DeviceMfgr = null
  state.deviceInfoState.DeviceModelNr = null
  state.deviceInfoState.UniqueDeviceID = null
}

export const clearProviderInfoMutator = async state => {
  state.providerInfoState.DataProviderReference = null
  state.providerInfoState.DataProviderString = null
  state.providerInfoState.ProviderID = null
  state.providerInfoState.ProviderIDSeries = null
  state.providerInfoState.TypeOfProvider = null
  state.providerInfoState.ContactURI = null
  state.providerInfoState.Language = null
}

export const clearServiceInfoMutator = async state => {
  state.serviceInfoState.DataProviderReference = null
  state.serviceInfoState.ServiceEnvironment = null
  state.serviceInfoState.ServiceType = null
  state.serviceInfoState.ServiceMobility = null
}

export const clearSubscriberInfoMutator = async state => {
  state.subscriberInfoState.DataProviderReference = null
  // fn
  state.subscriberInfoState.SubscriberData.vcard.fn.text = null
  // n
  state.subscriberInfoState.SubscriberData.vcard.n.surname = null
  state.subscriberInfoState.SubscriberData.vcard.n.given = null
  state.subscriberInfoState.SubscriberData.vcard.n.additional = null
  state.subscriberInfoState.SubscriberData.vcard.n.prefix = null
  state.subscriberInfoState.SubscriberData.vcard.n.suffix = null
  state.subscriberInfoState.SubscriberData.vcard.n.suffix = null
  // lang
  state.subscriberInfoState.SubscriberData.vcard.lang = null
  // adr
  state.subscriberInfoState.SubscriberData.vcard.adr.parameters.type.text = null
  state.subscriberInfoState.SubscriberData.vcard.adr.parameters.label.text = null
  state.subscriberInfoState.SubscriberData.vcard.adr.pobox = null
  state.subscriberInfoState.SubscriberData.vcard.adr.ext = null
  state.subscriberInfoState.SubscriberData.vcard.adr.street = null
  state.subscriberInfoState.SubscriberData.vcard.adr.locality = null
  state.subscriberInfoState.SubscriberData.vcard.adr.region = null
  state.subscriberInfoState.SubscriberData.vcard.adr.code = null
  state.subscriberInfoState.SubscriberData.vcard.adr.country = null
  // tel
  state.subscriberInfoState.SubscriberData.vcard.tel.parameters.type = null
  state.subscriberInfoState.SubscriberData.vcard.tel.uri = null
  // email
  state.subscriberInfoState.SubscriberData.vcard.email.parameters.type.text = null
  state.subscriberInfoState.SubscriberData.vcard.email.text = null
  // key
  state.subscriberInfoState.SubscriberData.vcard.key.parameters.type.text = null
  state.subscriberInfoState.SubscriberData.vcard.key.uri = null
  // tz
  state.subscriberInfoState.SubscriberData.vcard.tz.text = null
}
