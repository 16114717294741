export default () => ({
  updated: false,
  heldState: {
    civicAddress: {
      country: null,
      A1: null, // national subdivisions (state, canton, region, province, prefecture)
      A2: null, // county, parish, gun (JP), district (IN)
      A3: null, // city, township, shi (JP)
      A4: null, // city division, borough, city district, ward, chou (JP)
      A5: null, // neighborhood, block
      PRM: null, // Road pre-modifier
      PRD: null, // leading street direction
      RD: null, // Primary road or street e.g. Broadway
      STS: null, // street suffix or type
      POD: null, // trailing street suffix
      POM: null, // Road post-modifier
      RDSEC: null, // Road section e.g. 14
      RDBR: null, // Road branch e.g. Lane 7
      RDSUBBR: null, // Road sub-branch e.g. Alley 8
      HNO: null, // house number
      HNS: null, // house number suffix
      LMK: null, // landmark or vanity address
      LOC: null, // additional location information
      FLR: null, // floor
      NAM: null, // name (residence and office occupant)
      PC: null, // postal/zip code
      BLD: null, // Building (structure) e.g. Hope Theatre
      UNIT: null, // Unit (apartment, suite) e.g. 12a
      ROOM: null, // Room e.g. 450F
      SEAT: null, // Seat (desk, cubicle, workstation) e.g. WS 181
      PLC: null, // Place-type e.g. office
      PCN: null, // Postal community name e.g. Leonia
      POBOX: null, // Post office box (P.O. box) e.g. U40
      ADDCODE: null // Additional Code e.g. 13203000003
    },
    lat: null,
    lng: null,
    method: null
  }
})
