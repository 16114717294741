import * as VueGoogleMaps from 'vue2-google-maps'

export default {
  install (Vue) {
    Vue.use(VueGoogleMaps, {
      load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY,
        // key: 'AIzaSyDDywX0CsYR1M8qaDBdn-K2yw5lGuNEETk' use this key for development,
        libraries: 'places' // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        /// / If you want to set the version, you can do so:
        // v: '3.26',
      }

      /// / If you intend to programmatically custom event listener code
      /// / (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
      /// / instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
      /// / you might need to turn this on.
      // autobindAllEvents: false,

      /// / If you want to manually install components, e.g.
      /// / import {GmapMarker} from 'vue2-google-maps/src/components/marker'
      /// / Vue.component('GmapMarker', GmapMarker)
      /// / then disable the following:
      // installComponents: true,
    })
  }
}
