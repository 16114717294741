<template>
  <v-dialog
    v-model="show"
    :max-width="maxWidth"
    :eager="eager"
  >
    <v-card tile color="secondary_card" :dark="$vuetify.theme.dark" >

      <v-toolbar color="primary_card" flat  >

        <v-toolbar-title :dark="$vuetify.theme.dark"> {{title}}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn
          icon
          :dark="$vuetify.theme.dark"
          @click="show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'DialogShared',
  props: ['value', 'max-width', 'eager', 'title'],
  data: () => ({
    show: false
  }),
  watch: {
    value (val) {
      this.show = val
    },
    show (val) {
      this.$emit('input', val)
    }
  }
}
</script>
