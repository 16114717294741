export default function connectionSettingsApiModule (instance) {
  return {

    /**
     * Returns connection settings (proxy)
     *
     * @returns {Promise<any>}
     */
    getConnectionSettings: async () => {
      const resource = '/connection-setting'
      return await instance.get(resource)
    },

    /**
     * Save connection settings (proxy)
     * @param data
     * @returns {Promise<*>}
     */
    saveConnectionSetting: async (data) => {
      const resource = '/connection-setting/update-or-create'
      return await instance.post(resource, data)
    },

    /**
     * Delete connection settings
     * @param data
     * @returns {Promise<*>}
     */
    deleteConnectionSetting: async (data) => {
      const resource = `/connection-setting/${data}`
      const response = await instance.delete(resource)
      return response
    }

  }
}
