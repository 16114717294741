<template>
  <div v-if="authUserGetter">

    {{ $t('welcome') }}, {{ authUserGetter.name.toUpperCase() }}

    <connection-button/>
    <abandoned-call-button/>
    <settings-button/>
    <monitoring-button v-if="false"/>
    <call-channels-button v-if="false"/>
    <admin-panel-button />
    <information-button v-if="false"/>
    <logout-button/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
/* Buttons */
import ConnectionButton from '@/app/widgets/top-bar-widget/buttons/ConnectionButton'
import AbandonedCallButton from '@/app/widgets/top-bar-widget/buttons/AbandonedCallButton'
import SettingsButton from '@/app/widgets/top-bar-widget/buttons/SettingsButton'
import MonitoringButton from '@/app/widgets/top-bar-widget/buttons/MonitoringButton'
import CallChannelsButton from '@/app/widgets/top-bar-widget/buttons/CallChannelsButton'
import AdminPanelButton from '@/app/widgets/top-bar-widget/buttons/AdminPanelButton'
import InformationButton from '@/app/widgets/top-bar-widget/buttons/InformationButton'
import LogoutButton from '@/app/widgets/top-bar-widget/buttons/LogoutButton'

export default {
  name: 'TopBarComponent',
  components: {
    ConnectionButton,
    AbandonedCallButton,
    SettingsButton,
    MonitoringButton,
    CallChannelsButton,
    AdminPanelButton,
    InformationButton,
    LogoutButton
  },
  computed: {
    ...mapGetters('global', ['authUserGetter'])
  }
}
</script>

<style scoped>

</style>
