<template>
  <v-select
    label="Microphone"
    :items="audioSettingsGetter.audioInputs"
    item-value="deviceId"
    item-text="label"
    return-object
    @change="updateMicrophone"
    :value="audioSettingsGetter.microphoneDeviceId"
  ></v-select>
</template>
<script>
import { mapGetters } from 'vuex'
import localStorageService from '@/util/local-storage-service'

export default {
  name: 'MicrophoneComponent',
  computed: {
    ...mapGetters('settings', [
      'audioSettingsGetter'
    ])
  },
  methods: {
    updateMicrophone (device) {
      localStorageService.setMicrophoneId(device.deviceId)
    }
  }
}
</script>
