export default function speedDialApiModule (instance) {
  return {

    /**
     * Returns Contacts Hot Buttons.
     *
     * @return { Promise } response
     */
    get: async () => {
      const resource = '/contact-book/hot-button'

      const response = await instance.get(resource)

      return response
    },

    /**
     * Create Contacts Hot Buttons.
     *
     * @param { Object } data
     *
     * @return { Promise } response
     */
    create: async (data) => {
      const resource = '/contact-book/hot-button'

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Edit Contacts Hot Buttons by id.
     *
     * @param { Number } id
     *
     * @param { Object } data
     *
     * @return { Promise } response
     */
    edit: async (id, data) => {
      const resource = `/contact-book/hot-button/${id}`

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Delete Contacts Hot Buttons by id.
     *
     * @param { Number } id
     *
     * @return { Promise } response
     */
    delete: async (id) => {
      const resource = `/contact-book/hot-button/${id}`

      const response = await instance.delete(resource)

      return response
    }
  }
}
