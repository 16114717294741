
export default function callHistoryApiModule (instance) {
  return {

    /**
     * Get list of call history.
     * @param data
     * @returns {Promise<*>}
     */
    getCallsHistory: async (data) => {
      const resource = '/chs/call-history'
      return await instance.get(resource, {
        params: data
      })
    },

    /**
     * Find records from call history
     * @param data
     * @returns {Promise<*>}
     */
    findCallsHistory: async (data) => {
      const resource = '/chs/call-history/search'
      return await instance.get(resource, {
        params: data
      })
    },

    /**
     * Save call to history
     * @param data
     * @returns {Promise<*>}
     */
    saveCallHistory: async (data) => {
      const resource = '/chs/call-history'
      return await instance.post(resource, data)
    }

  }
}
