export default function callHistoryLocalCacherModule (indexDB) {
  return {
    get: function (payload) {
      const data = indexDB.callHistory.get(payload)

      return data
    },
    updateTable: (data) => {
      indexDB.callHistory.insertMany(data)
    },

    clear () {
      indexDB.callHistory.clear()
    }
  }
}
