import localStorageService from '@/util/local-storage-service'

export default function authApiModule (instance) {
  return {

    /**
     * Refresh authentication token
     * @returns {*}
     */
    refreshToken () {
      const data = {
        refresh_token: localStorageService.getRefreshToken()
      }
      const headers = {
        'Content-Type': 'application/json'
      }

      return instance.post('auth/refresh-token', data, { headers })
    },

    /**
     * Authenticate user
     * @param payload
     * @returns {*}
     */
    login (payload) {
      return instance.post('auth/login', payload)
    },

    /**
     * Logout user
     * @returns {*}
     */
    logout () {
      return instance.post('auth/logout')
    }

  }
}
