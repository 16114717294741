export default function egressApiModule (instance) {
  return {

    /**
     * Get authorization account
     * @param data
     * @returns {Promise<*>}
     */
    getAuthorization: async (data) => {
      const resource = '/egress-service/authorization'
      return await instance.get(resource)
    },

    /**
     * Get users
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getUsers: async (accountId, params) => {
      const resource = `/egress-service/users/${accountId}`
      return await instance.get(resource, {
        params: params
      })
    },

    /**
     * Get users list
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getUserList: async (accountId, params) => {
      const resource = `egress-service/accounts/${accountId}/users`
      return await instance.get(resource, {
        params: params
      })
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getAccounts: async (accountId, params) => {
      const resource = `/egress-service/accounts/${accountId}`
      return await instance.get(resource, {
        params: params
      })
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getHoldConferences: async (accountId) => {
      const resource = `/egress-service/accounts/${accountId}/conferences/on-hold`
      return await instance.get(resource)
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getConferenceParticipants: async (accountId, conferenceId) => {
      const resource = `/egress-service/accounts/${accountId}/conferences/${conferenceId}/participants`
      return await instance.get(resource)
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    kickConferenceParticipant: async (accountId, conferenceId, participantId) => {
      const resource = `/egress-service/accounts/${accountId}/conferences/${conferenceId}/participants/${participantId}/kick`
      return await instance.put(resource)
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    dialConference: async (accountId, conferenceId) => {
      const resource = `/egress-service/accounts/${accountId}/conferences/${conferenceId}/dial`
      return await instance.put(resource)
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    putConferenceOnHold: async (accountId, conferenceId, params) => {
      const resource = `/egress-service/accounts/${accountId}/conferences/${conferenceId}/on-hold`
      return await instance.put(resource, params)
    }
  }
}
