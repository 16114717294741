export default function userSettingsApiModule (instance) {
  return {

    /**
     * Return list of user settings
     * @param data
     * @returns {Promise<*>}
     */
    load: async (data) => {
      const resource = `/chs/settings/user?user_id=${data.user_id}&realm_name=${data.realm_name}`

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Update User Setting
     * @param id
     * @param data
     * @param userId
     * @param realmName
     * @returns {Promise<*>}
     */
    edit: async (id, data, userId = null, realmName = null) => {
      let resource = `/chs/settings/user/${id}`
      if (userId != null && realmName != null) {
        resource = `/chs/settings/user/${id}?user_id=${userId}&realm_name=${realmName}`
      }

      const response = await instance.patch(resource, data)

      return response
    }

  }
}
