export default () => ({
  updated: false,
  commentState: {
    DataProviderReference: null,
    Comment: null
  },
  deviceInfoState: {
    DataProviderReference: null,
    DeviceClassification: null,
    DeviceMfgr: null,
    DeviceModelNr: null,
    UniqueDeviceID: null
  },
  providerInfoState: {
    DataProviderReference: null,
    DataProviderString: null,
    ProviderID: null,
    ProviderIDSeries: null,
    TypeOfProvider: null,
    ContactURI: null,
    Language: null
  },
  serviceInfoState: {
    DataProviderReference: null,
    ServiceEnvironment: null,
    ServiceType: null,
    ServiceMobility: null
  },
  subscriberInfoState: {
    DataProviderReference: null,
    SubscriberData: {
      vcard: {
        fn: {
          text: null
        },
        n: {
          surname: null,
          given: null,
          additional: null,
          prefix: null,
          suffix: null
        },
        lang: [],
        adr: {
          parameters: {
            type: {
              text: null
            },
            label: {
              text: null
            }
          },
          pobox: null,
          ext: null,
          street: null,
          locality: null,
          region: null,
          code: null,
          country: null
        },
        tel: {
          parameters: {
            type: []
          },
          uri: null
        },
        email: {
          parameters: {
            type: {
              text: null
            }
          },
          text: null
        },
        key: {
          parameters: {
            type: {
              text: null
            }
          },
          uri: null
        },
        tz: {
          text: null
        }
      }
    }
  }
})
