<template>
  <validation-observer ref="observer" >
    <v-form class="pt-4" :disabled="disabled" @submit.prevent="onSubmit" novalidate>
      <v-row dense>
        <v-col>
          <v-alert
            dense
            type="info"
          >
            {{ $t('for_the_best_look_use_a_square_picture')}}
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="realm" :name="$t('realm')" rules="required">

            <v-select
              :label="$t('realm')"
              v-model="form.realm"
              :items="realms"
              item-value="id"
              item-text="description" :error-messages="errors"
            ></v-select>

          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="ringtone" :name="$t('file')" rules="required">

            <v-file-input
              v-model="form.logo"
              truncate-length="15"
              name="file"
              accept=".jpeg,.jpg,.png"
              :label="$t('choose_file')"
              show-size
              :disabled="disabled" :error-messages="errors"
            ></v-file-input>

          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-btn class="mr-5" color="passive_button" :disabled="disabled"  @click="onCancel()">
            {{ $t('cancel')}}
          </v-btn>
          <v-btn :disabled="disabled" type="submit" color="red"  >
            {{ $t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>
<script>

import { mapActions } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'
import apiService from '@/modules/api/csp'

export default {
  name: 'LogoUploadFormComponent',
  props: [],
  data: () => ({
    form: {
      realm: '',
      logo: null
    },

    realms: [],

    disabled: false
  }),
  components: {
    ValidationObserver,
    ValidationProvider

  },
  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    ...mapActions('settings', [
      'sipAddressesAction',
      'sipDefaultAddressAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.disabled = true

        apiService.logos.upload(this.form).then((response) => {
          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('logo_uploaded')
          })

          this.disabled = false
          this.$emit('onSubmit')
        }).catch((error) => {
          this.disabled = false
          this.$refs.observer.setErrors(error.response.data.errors)
        })
      }
    },
    onCancel () {
      this.$emit('onCancel')
    },

    loadRealms () {
      this.loading = true
      apiService.sip.getRealms().then((result) => {
        this.realms = result.filter(n => n != null)
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    }

  },
  async mounted () {
    await this.loadRealms()
  }

}
</script>
