import proxyComponent from '@/modules/api/csp/components/proxy.component'

export default function urlProxyApiModule (instance) {
  return {

    ...proxyComponent,

    resource () {
      return 'url-proxy'
    }

  }
}
