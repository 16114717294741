// for multiple requests
import localStorageService from '@/util/local-storage-service'
import apiService from '@/modules/api/csp'
import router from '@/router'

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const responseInterceptor = (axiosInstance) => (error) => {
  const _axios = axiosInstance
  const originalRequest = error.config

  if (String(error.response.data?.message).includes('refresh token is invalid')) {
    localStorageService.clearLocalStorageOnLogout()
    return router.push({ name: 'Login' }).catch()
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      }).then(token => {
        originalRequest.headers.Authorization = 'Bearer ' + token
        return _axios.request(originalRequest)
      }).catch(err => {
        return Promise.reject(err)
      })
    }

    originalRequest._retry = true
    isRefreshing = true

    return new Promise((resolve, reject) => {
      apiService.auth.refreshToken()
        .then(({ data }) => {
          localStorageService.setUserTokens({
            userAccessToken: data.access_token,
            refreshToken: data.refresh_token
          })
          originalRequest.headers.Authorization = 'Bearer ' + data.access_token
          processQueue(null, data.access_token)
          resolve(_axios(originalRequest))
        })
        .catch((err) => {
          processQueue(err, null)
          reject(err)
        })
        .then(() => { isRefreshing = false })
    })
  }

  return Promise.reject(error)
}

export default responseInterceptor
