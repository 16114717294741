<template>
  <v-badge
    color="red"
    overlap
    top
    right
    offset-x="20"
    :value="getAbandonedGetter"
    offset-y="20"
    :content="getAbandonedGetter"
  >
    <template id="phone_missed">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            color="grey_light"
            text
            v-on="on"
          >
            <v-icon>
              mdi-phone-missed
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('abandoned_call') }}</span>
      </v-tooltip>
    </template>
  </v-badge>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AbandonedCallButton',
  computed: {
    ...mapGetters('abandonedCalls', ['getAbandonedGetter'])
  },
  data: () => ({
    count: 0
  }),
  async mounted () {
    const data = await this.$localCacher.indexDB.abandonedCalls.get()

    this.count = data.value ? data.value.length : 0
  }
}
</script>

<style scoped>

</style>
