import apiService from '@/modules/api/csp'
import CallHistoryTransformer from '@/modules/api/csp/transformers/call-history.transformer'
import EventDispatcher from '@/modules/event/event-dispatcher.module'

export const getCallHistoryAction = async ({ commit, dispatch }, payload = {}) => {
  let response = {}

  if (payload.call_from && payload.call_from.length > 0) {
    response = await apiService.callHistory.findCallsHistory(payload)
  } else {
    response = await apiService.callHistory.getCallsHistory(payload)
  }

  commit('setCallHistoryMutator', response.data)

  return response
}

export const saveCallHistoryAction = async ({ commit, dispatch }, payload = []) => {
  const collectData = new CallHistoryTransformer(payload).collectData()
  if (collectData) {
    await apiService.callHistory.saveCallHistory(collectData).then(async () => {
      const event = new EventDispatcher()
      event.dispatch('refreshCallHistory')
    })
  }
}
