<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn title="Admin"
             @click="callChannelsDialog()"
             icon
             color="grey_light"
             text
             v-on="on"
      >
        <v-icon>mdi-chart-bar</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('call_channels') }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'CallChannelsButton',
  methods: {
    callChannelsDialog () {}
  }
}
</script>

<style scoped>

</style>
