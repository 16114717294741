export const authUserGetter = (state) => {
  return state.authUserState
}
export const hasAnsweredCallGetter = (state) => {
  return state.hasAnsweredCall
}

export const sipGetter = (state) => {
  return state.sipState
}

export const callGetter = (state) => {
  return state.callState
}

export const serialDevicesGetter = (state) => {
  return state.serialDevices
}
