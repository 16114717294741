export default function fccMasterRegistry (instance) {
  return {

    search: async (data) => {
      const resource = '/fcc/search-master-registry'

      const response = await instance.get(resource, {
        params: data
      })

      return response
    }
  }
}
