<template>
  <div>

    <v-card tile flat color="secondary_card">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="openToCreate" cypress="sip_address_add">
              <v-icon color="error">
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('add_new')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="openToEdit" icon v-on="on" :disabled="!!!$can('chs_can_edit_sip_address') || selected.length === 0 " cypress="sip_address_edit">
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('edit') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn  v-on="on" icon :loading="loading.delete" :disabled="!!!$can('chs_can_delete_sip_address')" cypress="sip_address_delete">
              <v-icon color="error" @click="removeSipAddress" :disabled="selected.length === 0">
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('delete') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">

            <v-btn  :disabled="!!!$can('chs_can_edit_sip_address')"  v-on="on" icon :loading="loading.default" cypress="sip_address_default">

              <v-icon color="success" @click="makeDefaultSipAddress" :disabled="selected.length === 0">
                mdi-check-bold
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('make_default') }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table class="transparent-background"
          cypress="sip_address_data"
          :headers="headers"
          :items="sipAddressesGetter"
          :loading="loading.data"
          :items-per-page="10"
          show-select
          single-select
          v-model="selected"
        >
          <template v-slot:[`item.default`]="{ item }">
            <template v-if="item.default">
              {{ $t('default') }}
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <validation-observer ref="observer" >

    <DialogShared v-model="dialog" eager max-width="600" :title="dialogTitle" class="white--text">

      <v-form class="pt-10" ref="form" novalidate >
        <v-row tile class="white--text">
          <v-col cols="6">

            <validation-provider v-slot="{ errors }" :name="$t('sip_address')" rules="required|sipAddress">
              <v-text-field
                cypress="add_sip_address"
                :error-messages="errors"
                name="sip_address"
                :label="$t('sip_address') + '*'"
                @input="findMatchingAccountNameForSipAddress"
                :dark="$vuetify.theme.dark"
                clearable
                outlined
                counter="100"
                hint="username@domain.com"  v-model="newSipAddress.sip_address"
              ></v-text-field>

            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" :name="$t('display_name')" rules="required">
              <v-text-field
                cypress="add_display_name"
                :error-messages="errors"
                name="display_name"
                :label="$t('display_name')"
                :dark="$vuetify.theme.dark"
                clearable
                outlined
                counter="100"
                :hint="$t('eg_alice')"   v-model="newSipAddress.display_name"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" :name="$t('your_account_name')" rules="required">
              <v-autocomplete
                cypress="add_account_name"
                :error-messages="errors"
                name="account_name"
                :label="$t('account_name') + '*'"
                :items="accountNamesGetter"
                :dark="$vuetify.theme.dark"
                outlined autocomplete="new-password"
                :hint="$t('your_account_name')"   v-model="newSipAddress.account_name"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" :name="$t('password')" rules="required">
              <v-text-field
                cypress="add_password"
                :error-messages="errors"
                name="password"
                :label="$t('password') + '*'"
                :dark="$vuetify.theme.dark"
                clearable
                outlined
                counter="100"
                type="password" autocomplete="new-password"
                :hint="$t('your_sip_address_password')" v-model="newSipAddress.password"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-form>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn
          cypress="add_sip_address_email"
          color="error"
          class="pa-7"
          v-if="mode === 'create'"
          :loading="loading.add"
          :disabled="loading.add" @click="addSipAddress()"
        >
          {{ $t('add') }}
        </v-btn>
        <v-btn
          cypress="update_sip_address"
          color="error"
          class="pa-7"
          v-if="mode === 'edit'"
          :loading="loading.edit"
          :disabled="loading.edit" @click="edit()"
        >
          {{ $t('edit') }}
        </v-btn>
      </template>
    </DialogShared>

    </validation-observer>
  </div>
</template>

<script>
import DialogShared from '@/app/shared/dialog/DialogShared'
import { mapActions, mapGetters } from 'vuex'

import apiService from '@/modules/api/csp'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {
  name: 'SipSettingsTab',

  data: () => ({

    loading: {
      data: false,
      add: false,
      edit: false,
      delete: false,
      default: false
    },
    dialog: false,
    mode: 'create',
    newSipAddress: {
      sip_address: null,
      display_name: null,
      account_name: null,
      account_id: null,
      realm: null,
      password: null
    },
    selected: [],
    valid: false
  }),
  computed: {
    headers () {
      return [
        { text: this.$t('sip_address'), value: 'sip_address' },
        { text: this.$t('display_name'), value: 'display_name' },
        { text: this.$t('account_name'), value: 'account_name' },
        { text: this.$t('default'), value: 'default' }
      ]
    },
    dialogTitle () {
      return this.mode === 'create' ? this.$t('add_new_sip_address') : this.$t('edit_sip_address')
    },

    ...mapGetters('global', [
      'authUserGetter'
    ]),

    ...mapGetters('settings', [

      'sipAddressesGetter',
      'sipDefaultAddressGetter',

      'accountsGetter',
      'accountNamesGetter'
    ])
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    DialogShared
  },

  async mounted () {
  },

  watch: {
    dialog (val) {
      if (!val) {
        this.$refs.observer.reset()
      }
    }
  },

  methods: {

    ...mapActions('logo', [
      'loadLogoAction'
    ]),

    ...mapActions('settings', [
      'sipAddressesAction',
      'sipDefaultAddressAction'
    ]),

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async removeSipAddress () {
      this.loading.delete = true

      await apiService.sip.removeSipAddress(this.selected[0].id)
        .then(() => { this.sipAddressesAction(this.authUserGetter.id) })
        .then(() => {
          this.loading.delete = false
          this.selected = []
        })
        .catch(e => console.error(e))
    },

    openToCreate () {
      this.mode = 'create'

      this.newSipAddress = {
        username: null,
        displayName: null,
        account_name: null,
        account_id: null,
        realm: null,
        password: null,
        domain: null,
        proxy: null,
        port: null
      }

      this.dialog = !this.dialog
    },

    async makeDefaultSipAddress () {
      this.loading.default = true

      const data = {
        id: this.selected[0].id,
        user_id: this.authUserGetter.id,
        default: 1
      }

      await apiService.sip.updateDefaultSipAddress(data)
        .then(() => {
          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('sip_address_marked_as_default')
          })
          this.selected = []
        })
        .then(() => this.sipAddressesAction(this.authUserGetter.id))
        .then(() => this.sipDefaultAddressAction())
        .then(() => this.loadLogoAction())
        .catch(e => console.error(e))
        .finally(() => {
          this.loading.default = false
        })
    },

    openToEdit () {
      this.mode = 'edit'

      this.newSipAddress = { ...this.selected[0] }
      this.dialog = !this.dialog
    },

    async addSipAddress () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.setAccountForSipAddress()
        await this.validateAccountName()
        if (!this.accountNameIsValid) {
          return
        }

        this.loading.add = true

        const data = {
          user_id: this.authUserGetter.id,
          sip_address: this.newSipAddress.sip_address,
          display_name: this.newSipAddress.display_name,
          account_name: this.newSipAddress.account_name,
          account_id: this.newSipAddress.account_id,
          realm: this.newSipAddress.realm,
          password: this.newSipAddress.password
        }

        await apiService.sip.addSipAddress(data)
          .then(() => this.sipAddressesAction(this.authUserGetter.id))
          .then(() => this.sipDefaultAddressAction())
          .then(() => {
            this.dialog = false

            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('sip_address_created')
            })

            this.$refs.observer.reset()
          })
          .catch(async e => {
            await this.notificationSnackBarAction({
              text: this.$t('cant_add_sip_address'),
              color: 'error',
              show: true
            })
          })
          .finally(() => {
            this.loading.add = false
          })
      }
    },

    async edit () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.setAccountForSipAddress()
        await this.validateAccountName()
        if (!this.accountNameIsValid) {
          return
        }

        this.loading.edit = true

        const data = {
          ...this.newSipAddress,
          user_id: this.authUserGetter.id
        }

        await apiService.sip.updateSipAddress(data)
          .then(() => this.sipAddressesAction(this.authUserGetter.id))
          .then(() => this.sipDefaultAddressAction())
          .then(() => {
            this.dialog = false
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('sip_address_updated')
            })
            this.$refs.observer.reset()
          })
          .catch(async e => {
            await this.notificationSnackBarAction({
              text: this.$t('cant_update_sip_address'),
              color: 'error',
              show: true
            })
          })
          .finally(() => {
            this.loading.edit = false
          })
      }
    },

    async validateAccountName () {
      const account = this.accountsGetter.find(account => account.realm === this.newSipAddress.sip_address.split('@')[1])

      if (account) {
        if (account.id === this.newSipAddress.account_id &&
          account.name === this.newSipAddress.account_name
        ) {
          this.accountNameIsValid = true
          return
        }
      }

      this.accountNameIsValid = false

      await this.notificationSnackBarAction({
        text: this.$t('please_choose_a_valid_account_name'),
        color: 'error',
        show: true
      })
    },

    findMatchingAccountNameForSipAddress () {
      if (this.newSipAddress.sip_address) {
        const account = this.accountsGetter.find(account => account.realm === this.newSipAddress.sip_address.split('@')[1])

        if (account) {
          this.newSipAddress.account_name = account.name
        }
      }
    },

    setAccountForSipAddress () {
      if (this.newSipAddress.account_name) {
        const account = this.accountsGetter.find(account => account.name === this.newSipAddress.account_name)

        if (account) {
          this.newSipAddress.account_id = account.id
          this.newSipAddress.realm = account.realm
        }
      } else {
        this.newSipAddress.account_id = null
        this.newSipAddress.realm = null
      }
    }
  }
}
</script>

<style lang="scss">

</style>
