<template>
  <v-card tile flat color="secondary_card">
    <v-card-text>
      <DialogShared v-model="dialog" max-width="900" scrollable :title="dialogTitle">
        <UserRealmSettingForm :key="formKey" :entity="setting" :user="entity" :realm_name="realm_name" @onCancel="dialog = false" @onSubmit="onFormSubmit" />
      </DialogShared>

      <v-alert dense type="info" color="red lighten-2" >
        {{ $t('realm')}} <b>{{ realm_name }}</b>
      </v-alert>

      <v-alert dense type="info">
        {{ $t('changes_will_be_applied_after_relogin_or_page_refresh')}}
      </v-alert>

      <v-data-table :disable-pagination="true" :hide-default-footer="true" class="transparent-background"
          :items="settings"
          :loading="loading"
          :headers="headers"
      >

        <template v-slot:[`item.value`]="{ item }">

          <span v-if="item.setting.type == 'checkbox'">
             <v-switch :disabled="loading" dense flat inset @change="onItemChange(item)" v-model="item.value.active" :label="item.value.active ? 'Enabled' : 'Disabled'" ></v-switch>
          </span>
          <span v-else>
            Unsupported Type {{ item.type }} with value {{ item.value }}
          </span>

        </template>

        <template v-slot:[`item.description`]="{ item }">

          {{ item.setting.description}}

        </template>

        <template v-slot:[`item.actions`]="{ item }">

          <v-btn :disabled="loading" class="mr-1" color="green" small @click.stop="onEdit(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon smalls v-bind="attrs" v-on="on">
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('edit')}}</span>
            </v-tooltip>
          </v-btn>

        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import UserRealmSettingForm from '@/app/widgets/admin-panel-widget/components/user/UserRealmSettingsForm'
import DialogShared from '@/app/shared/dialog/DialogShared'
import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'

export default {

  name: 'UserRealmSettingsList',

  props: ['entity', 'realm_name'],

  components: { UserRealmSettingForm, DialogShared },

  data: (vm) => ({

    setting: null,
    formKey: 0,
    dialogTitle: vm.$t('update_user_realm_setting'),
    dialog: false,
    settings: [],

    loading: false,

    headers: [
      { text: vm.$t('value'), value: 'value', sortable: false },
      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('description'), value: 'description', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  async mounted () {
    await this.loadUserRealmSettings()
  },

  computed: {

  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    refreshForm () {
      this.formKey += 1
    },

    loadUserRealmSettings () {
      this.loading = true

      apiService.userSettings.load({
        user_id: this.entity.id,
        realm_name: this.realm_name
      }).then((result) => {
        this.settings = result.data.data

        this.loading = false
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async onItemChange (item) {
      this.loading = true

      const data = {
        value: item.value
      }

      await apiService.userSettings.edit(item.setting.id, data, this.entity.id, this.realm_name).then((response) => {
        this.loading = false
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })

        this.loading = false
      })
    },

    onFormSubmit () {
      this.dialog = false
      this.loadUserRealmSettings()
    },

    onEdit (item) {
      this.dialogTitle = this.$t('update_user_realm_setting')
      this.refreshForm()
      this.setting = item
      this.dialog = true
    }

  }

}
</script>
