export const dateTimeGetter = state => {
  return state.dateTimeState
}

export const esrkNumberGetter = state => {
  return state.esrkNumberState
}

export const nameGetter = state => {
  return state.nameState
}
