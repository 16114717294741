import store from '../store'

/**
 * Permission Helper
 * @type {{can(*=): (boolean|*), canAny(*): (boolean|*)}}
 */
const permissionHelper = {

  /**
     * Check if logged user has any permission
     * @param permissions
     * @returns {boolean|*}
     */
  canAny (permissions) {
    try {
      if (!Array.isArray(permissions)) {
        permissions = [permissions]
      }

      permissions = permissions.map(perm => perm.toLowerCase())

      return store.getters['global/authUserGetter'].permissions.map(perm => perm.toLowerCase()).some(v => permissions.includes(v))
    } catch (e) {
      return false
    }
  },

  /**
     * Check if logged user has permission
     * @param permissionName
     * @returns {boolean|*}
     */
  can (permissionName) {
    try {
      return store.getters['global/authUserGetter'].permissions.map(perm => perm.toLowerCase()).includes(permissionName.toLowerCase())
    } catch (e) {
      return false
    }
  }
}

export default permissionHelper
