export default class CallMonitoringModule {
  session;

  monitorCall (session) {
    if (session._request.hasHeader('Call-Monitoring')) {
      this.session = session
      this.checkCallType()
    }
  }

  checkCallType () {
    switch (this.session._request.getHeader('Call-Monitoring')) {
      case 'BARGE':
        this.barge()
        break
      case 'OBSERVE':
        this.observe()
        break
      case 'WHISPER':
        this.whisper()
        break
    }
  }

  barge () {
    this.sendDTMF(3)
  }

  observe () {

  }

  whisper () {
    this.sendDTMF(2)
  }

  sendDTMF (dtmfCode) {
    this.session.sendDTMF(dtmfCode, {
      transportType: 'RFC2833'
    })
  }
}
