export default {
  methods: {
    async playPreRecordedAudio () {
      const audio = new Audio()
      audio.crossOrigin = 'anonymous'
      audio.src = 'https://www.openphone.co/blog/wp-content/uploads/2020/01/VM-15.mp3'
      await audio.play().then(() => {
        const mediaStream = audio.captureStream()
        const getAudioTracks = mediaStream.getAudioTracks()

        this.$sip.answeredCallSession.connection.getSenders().forEach(item => {
          item.replaceTrack(getAudioTracks[0])
        })
        this.replaceMicrophoneAudio(audio)
      })
    },
    replaceMicrophoneAudio (audio) {
      audio.addEventListener('ended', async () => {
        await navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then(device => {
          this.$sip.answeredCallSession.connection.getSenders().forEach(item => {
            item.replaceTrack(device.getAudioTracks()[0])
          })
        })
      })
    }
  }
}
