import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'

/**
 * Authorize user
 * @param dispatch
 * @param payload
 * @returns {Promise<*>}
 */
export const authorizeAction = async ({ dispatch }, payload) => {
  const response = await apiService.auth.login(payload)

  localStorageService.setUserTokens({
    userAccessToken: response.data.access_token,
    refreshToken: response.data.refresh_token
  })

  return response
}

/**
 * Logout user
 * @param context
 * @param payload
 * @returns {Promise<void>}
 */
export const authLogoutAction = async (context, payload) => {
  await apiService.auth.logout().then((response) => {
    localStorage.removeItem('user_access_token')
    localStorage.removeItem('refresh_token')

    context.commit('authUserMutator', null)
  }).catch(error => {
    console.error('logout error', error)
  })
}

/**
 * Has answered call
 * @param context
 * @param payload
 * @returns VoidFunction
 */
export const hasAnsweredCallAction = async (context, payload) => {
  context.commit('hasAnsweredCallMutator', null)
}
