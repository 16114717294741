<template>
  <div>

    <DialogShared v-model="dialog" max-width="1200" scrollable :title="dialogTitle">
      <LogoUploadForm :key="formKey" @onCancel="dialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <div class="text-right">
      <v-btn @click="onCreate" icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">
              mdi-upload
            </v-icon>
          </template>
          <span> {{ $t('upload_logo')}}</span>
        </v-tooltip>
      </v-btn>
    </div>

    <v-data-table class="transparent-background"  :dark="$vuetify.theme.dark"
                  :items="logos"
                  :loading="loading"
                  :options.sync="options"
                  :headers="headers"
                  :footer-props="{itemsPerPageOptions : [5,10,15]}"
    >

      <template v-slot:[`item.thumbnail`]="{ item }" >
        <div class="pa-2">
          <img :src="item.thumbnail" height="90px" />
        </div>

      </template>
      <template v-slot:[`item.actions`]="{ item }">

        <v-btn class="mr-1" color="error" small @click.stop="onDelete(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-delete-outline
              </v-icon>
            </template>
            <span> {{ $t('delete')}}</span>
          </v-tooltip>
        </v-btn>

      </template>

    </v-data-table>

  </div>
</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'
import LogoUploadForm from '@/app/widgets/admin-panel-widget/components/logo/LogoUploadFormComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'

export default {

  name: 'LogosSettingsTab',

  components: { DialogShared, LogoUploadForm },
  data: (vm) => ({

    formKey: 0,
    dialogTitle: vm.$t('upload_logo'),
    dialog: false,
    totalLogos: 0,
    logos: [],
    options: {
      page: 1,
      itemsPerPage: 10
    },
    loading: false,
    headers: [

      { text: vm.$t('logo'), value: 'thumbnail', sortable: false },
      { text: vm.$t('realm'), value: 'realm', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  watch: {

    options: {
      handler () {
        this.loadLogos()
      },
      deep: true
    }
  },

  mounted () {
    this.loadLogos()
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    refreshForm () {
      this.formKey += 1
    },

    loadLogos () {
      this.loading = true

      apiService.logos.getAll().then((result) => {
        this.logos = result.data.data
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onFormSubmit () {
      this.dialog = false
      this.loadLogos()
    },

    onCreate () {
      this.dialogTitle = this.$t('upload_logo')

      this.refreshForm()
      this.dialog = true
    },

    onDelete (item) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('logo') })).then(confirmed => {
        if (confirmed) {
          apiService.logos.delete(item.realm).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('logo_deleted')
            })

            this.loadLogos()
          }).catch((error) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message
            })
          })
        }
      })
    }
  }

}
</script>

<style scoped>

</style>
