import apiService from '@/modules/api/csp'
import LocalCacher from '@/modules/cache/localCacher'

export async function getContactBookAction ({ commit }, payload = {}) {
  let response = {}

  // eslint-disable-next-line no-prototype-builtins
  if (!payload.hasOwnProperty('page')) {
    payload.page = 1
  }
  // eslint-disable-next-line no-prototype-builtins
  if (!payload.hasOwnProperty('limit')) {
    payload.limit = 10
  }

  if (payload && payload.search && payload.search.length > 0) {
    response = await apiService.contactBook.search(payload)
  } else {
    response = await apiService.contactBook.get(payload)
  }

  commit('setContactBookMutator', response.data)

  return response
}

export async function createContactBookAction ({ context, dispatch }, payload) {
  await apiService.contactBook.create(payload)
  return await dispatch('getContactBookAction')
}

export async function updateContactBookAction ({ commit, context, dispatch }, payload) {
  await apiService.contactBook.edit(payload.id, payload.data)
  LocalCacher.indexDB.contactBooks.edit(payload.id, payload.data)
  await dispatch('getContactBookAction')
}

export async function deleteContactBookAction ({ commit, context, dispatch }, payload) {
  await apiService.contactBook.delete(payload.id)
  LocalCacher.indexDB.contactBooks.delete(payload.id)
  await dispatch('getContactBookAction', payload)
}

/**
 * get Contact book Groups
 * @param commit
 * @param payload
 * @returns {Promise<*>}
 */
export async function getContactGroupsAction ({ commit }, payload) {
  return await apiService.contactGroups.get(payload).then((response) => {
    commit('setContactGroupsMutator', response.data.data)
  }).catch()
}
