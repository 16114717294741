<template>
  <div>

    <v-form :disabled="loading" ref="form" @submit.prevent="saveUserLayout" novalidate>
      <v-card tile flat color="secondary_card">
        <v-card-text>
          <v-select
            :label="$t('layout')"
            v-model="current"
            :items="layoutsGetter"
            item-value="id"
            item-text="name"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" :loading="loading" @click="saveUserLayout">{{ $t('save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import apiService from '@/modules/api/csp'

export default {
  name: 'LayoutSettingTab',
  data: () => ({
    loading: false,
    current: null,
    layouts: []
  }),

  computed: {
    ...mapGetters('global', ['authUserGetter']),
    ...mapGetters('layout', ['layoutsGetter'])
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    ...mapActions('layout', [
      'updateLayoutAction'
    ]),

    saveUserLayout () {
      this.loading = true
      apiService.layouts.assignLayout(this.current).then(result => {
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: result.data.message
        })
        apiService.layouts.get(this.current).then(result => {
          this.updateLayoutAction(result.data.data)
        })
        this.loading = false
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('cant_assign_layout_please_try_again')
        })
        this.loading = false
      })
    }
  },
  async mounted () {
    if (this.authUserGetter.layout) {
      this.current = this.authUserGetter.layout.id
    }
  }
}
</script>
