<template>
  <div>
    <v-list flat class="list transparent">
      <v-list-item class="col-4">
        <v-list-item-content class="font-weight-medium">
          {{ $t('multi_factor_authentication') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-switch dense flat inset v-model="show.twoFA.button"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4" v-if="show.twoFA.button">
        <v-list-item-content>
          <div>
            <v-btn large class="text-capitalize pr-7" color="error" @click="show.twoFA.phoneInput = true">
              <v-icon color="white" class="pl-4 pr-4">
                mdi-dialpad
              </v-icon>
              {{ $t('authorization_with_phone_number') }}
            </v-btn>
          </div>
          <div class="mt-5" v-if="show.twoFA.phoneInput">
            <v-text-field :label="$t('phone_number')" outlined background-color="primary_card" class="mt-0">
              <template v-slot:append v-if="show.twoFA.codeValid">
                <span>
                  <v-icon color="green">mdi-check-circle</v-icon>
                </span>
              </template>
              <template slot="append-outer">
                <v-btn fab tile class="rounded" elevation="0" :color="show.twoFA.codeSent ? 'primary' : 'error'" @click="send2FACode">
                  <v-icon v-if="show.twoFA.codeSent">mdi-refresh</v-icon>
                  <v-icon v-else>mdi-send</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field :label="$t('tfa_code')" outlined background-color="primary_card" v-if="show.twoFA.codeSent" hide-details>
              <div slot="append-outer">
                <v-btn fab tile class="rounded" elevation="0" color="error" @click="validate2FACode">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </div>
            </v-text-field>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4">
        <v-list-item-content class="font-weight-medium">
          {{ $t('lightdark_mode') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-switch dense flat inset v-model="$vuetify.theme.dark" @change="saveThemeStyle"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4">
        <v-list-item-content class="font-weight-medium">
          {{ $t('leftright_dial_pad') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-switch dense flat inset></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4">
        <v-list-item-content class="font-weight-medium">
          {{ $t('set_prefix_for_outgoing_calls') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-switch dense flat inset v-model="show.outgoingCalls"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item class="col-4" v-if="show.outgoingCalls">
        <v-list-item-content>
          <v-text-field :label="$t('prefix')" outlined background-color="primary_card">
            <div slot="append-outer">
              <v-btn fab tile class="rounded" elevation="0" color="error">
                <v-icon>mdi-database</v-icon>
              </v-btn>
            </div>
          </v-text-field>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4">
        <v-list-item-content class="font-weight-medium">
          {{ $t('queue_auth_via_phone_number') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-switch dense flat inset v-model="show.queueAuth"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item class="col-4" v-if="show.queueAuth">
        <v-list-item-content>
          <v-text-field :label="$t('login_number')" outlined background-color="primary_card">
            <div slot="append-outer">
              <v-btn fab tile class="rounded" elevation="0" color="error">
                <v-icon>mdi-database</v-icon>
              </v-btn>
            </div>
          </v-text-field>
          <v-text-field :label="$t('logout_number')" outlined background-color="primary_card">
            <div slot="append-outer">
              <v-btn fab tile class="rounded" elevation="0" color="error">
                <v-icon>mdi-database</v-icon>
              </v-btn>
            </div>
          </v-text-field>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4">
        <v-list-item-content class="font-weight-medium">
          {{ $t('password_change') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="col-4">
        <v-row>
          <validation-observer ref="observer" tag="form" @submit.prevent="updatePassword" class="pt-4 col-12">
              <validation-provider v-slot="{ errors }" name="current_password" rules="required">
                <v-text-field
                  id="current_password"
                  name="current_password"
                  :error-messages="errors"
                  :label="$t('previous_password')"
                  outlined
                  :type="show.current_password ? 'text' : 'password'"
                  background-color="primary_card"
                  v-model="password_form.current_password"
                >
                  <template v-slot:append>
                    <span class="pointer">
                      <v-icon color="grey" @click="show.current_password = false" v-if="show.current_password">mdi-eye</v-icon>
                      <v-icon color="grey" @click="show.current_password = true" v-else>mdi-eye-off</v-icon>
                    </span>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="new_password" rules="required">
                <v-text-field
                  id="new_password"
                  name="new_password"
                  :error-messages="errors"
                  :label="$t('new_password')"
                  outlined
                  :type="show.new_password ? 'text' : 'password'"
                  background-color="primary_card"
                  v-model="password_form.new_password"
                >
                  <template v-slot:append>
                    <span class="pointer">
                      <v-icon color="grey" @click="show.new_password = false" v-if="show.new_password">mdi-eye</v-icon>
                      <v-icon color="grey" @click="show.new_password = true" v-else>mdi-eye-off</v-icon>
                    </span>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="repeat_new_password" rules="required">
                <v-text-field
                  id="new_password_confirmation"
                  name="new_password_confirmation"
                  :error-messages="errors"
                  :label="$t('repeat_new_password')"
                  outlined
                  :type="show.new_password_confirmation ? 'text' : 'password'"
                  background-color="primary_card"
                  v-model="password_form.new_password_confirmation"
                >
                  <template v-slot:append>
                    <span class="pointer">
                      <v-icon color="grey" @click="show.new_password_confirmation = false" v-if="show.new_password_confirmation">mdi-eye</v-icon>
                      <v-icon color="grey" @click="show.new_password_confirmation = true" v-else>mdi-eye-off</v-icon>
                    </span>
                  </template>
                </v-text-field>
              </validation-provider>
              <v-btn type="submit" large color="error">
                {{ $t('change_password') }}
              </v-btn>
          </validation-observer>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>

<style lang="scss" scoped>
  ::v-deep .v-input__append-outer {
    margin-top: 0px !important;
  }
</style>

<script>
import localStorageService from '@/util/local-storage-service'
import apiService from '@/modules/api/csp'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/rules/validation.rules.js'

export default {
  name: 'GeneralSettingTab',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    test: '',
    show: {
      twoFA: {
        button: false,
        codeSent: false,
        codeValid: false,
        codeInput: false,
        phoneInput: false
      },
      queueAuth: false,
      outgoingCalls: false,
      new_password: false,
      current_password: false,
      new_password_confirmation: false
    },
    password_form: {
      new_password: null,
      current_password: null,
      new_password_confirmation: null
    }
  }),
  mounted () {
    this.enableDarkMode = this.darkThemeGetter
  },
  methods: {
    async updatePassword () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      this.loading = true

      apiService.user.changePassword(this.password_form).then(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: 'Password Changed'
        })

        this.password_form.new_password = null
        this.password_form.current_password = null
        this.password_form.new_password_confirmation = null

        this.$nextTick(() => this.$refs.observer.reset())
      }).catch((error) => {
        if (error.response.data.errors) {
          this.$refs.observer.setErrors(error.response.data.errors)
        }

        if (error.response.data.message) {
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    send2FACode () {
      this.show.twoFA.codeSent = true
    },
    validate2FACode () {
      this.show.twoFA.codeValid = true
    },
    saveThemeStyle () {
      localStorageService.setThemeStyle(this.$vuetify.theme.dark)
    }
  },
  watch: {
    'show.twoFA.button' (val) {
      if (val === false) {
        this.show.twoFA.codeSent = false
        this.show.twoFA.codeInput = false
        this.show.twoFA.codeValid = false
        this.show.twoFA.phoneInput = false
      }
    }
  }
}
</script>

<style type="scss" scoped>

</style>
