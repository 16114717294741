<template>
  <div>

    <DialogShared v-model="dialog" max-width="900" scrollable :title="dialogTitle">
      <GlobalSettingsForm :key="formKey" :entity="entity" @onCancel="dialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <v-alert dense type="info">
      {{ $t('changes_will_be_applied_after_relogin_or_page_refresh')}}
    </v-alert>

    <v-data-table :disable-pagination="true" :hide-default-footer="true" class="transparent-background"
                  :items="settings"
                  :loading="loading"

                  :headers="headers"
    >

      <template v-slot:[`item.value`]="{ item }">

        <span v-if="item.type == 'checkbox'">

           <v-switch dense flat inset @change="onItemChange(item)" v-model="item.value.active" :label="item.value.active ? $t('enabled') : $t('disabled')" ></v-switch>

        </span>
        <span v-else>
          Unsupported Type {{ item.type }} with value {{ item.value }}
        </span>

      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-btn class="mr-1" color="green" small @click.stop="onEdit(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon smalls v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit')}}</span>
          </v-tooltip>
        </v-btn>

      </template>

    </v-data-table>

  </div>
</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions, mapGetters } from 'vuex'
import GlobalSettingsForm from '@/app/widgets/admin-panel-widget/components/settings/GlobalSettingsFormComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'

export default {

  name: 'GlobalSettingsTab',

  components: { GlobalSettingsForm, DialogShared },
  data: (vm) => ({

    entity: null,
    formKey: 0,
    dialogTitle: vm.$t('update_global_setting'),
    dialog: false,
    settings: [],
    loading: false,

    headers: [
      { text: vm.$t('value'), value: 'value', sortable: false },
      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('description'), value: 'description', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  mounted () {
    this.loadSettings()
  },

  computed: {
    ...mapGetters('settings', [
      'globalSettingsGetter',
      'getGlobalSetting'
    ])
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    ...mapActions('settings', [
      'setGlobalSettingsAction',
      'loadGlobalSettingsAction'
    ]),

    refreshForm () {
      this.formKey += 1
    },

    loadSettings () {
      this.loading = true

      apiService.globalSettings.load().then((result) => {
        this.settings = result.data.data

        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async onItemChange (item) {
      apiService.globalSettings.edit(item.id, item).then((response) => {
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: this.$t('global_setting_updated')
        })

        this.loadGlobalSettingsAction()
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onFormSubmit () {
      this.dialog = false
      this.loadSettings()
      this.loadGlobalSettingsAction()
    },

    onEdit (item) {
      this.dialogTitle = this.$t('update_global_setting')
      this.refreshForm()
      this.entity = item
      this.dialog = true
    }

  }

}
</script>

<style scoped>

</style>
