<template>
  <v-container no-gutters>
    <v-row no-gutters >
      <v-col cols="12">

        <v-tabs class="font-weight-bold" height="40" color="white" background-color="transparent" v-model="tab" show-arrows :dark="$vuetify.theme.dark" >
          <template v-for="item in tabs">
            <v-tab v-if="(item.permission === '') || $can(item.permission)" :key="item.name"
                   @change="changeTab(item.name)">
              {{ item.name }}
            </v-tab>
          </template>
        </v-tabs>

        <v-tabs-items  v-model="tab" :dark="$vuetify.theme.dark" >
          <template v-for="item in tabs">
            <v-tab-item
                v-if="(item.permission === '') || $can(item.permission)"
                :key="item.name"
                :cypress=true
                :eager="item.eager"
            >
              <v-card tile flat color="secondary_card">
                <v-card-text >
                  <component :is="item.component"></component>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </template>
        </v-tabs-items>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import RolesSettingsTab from '@/app/widgets/admin-panel-widget/tabs/RolesTab'
import GlobalSettingsTab from '@/app/widgets/admin-panel-widget/tabs/GlobalSettingsTab'
import AccountSettingsTab from '@/app/widgets/admin-panel-widget/tabs/AccountSettingsTab'
import GlobalRingtoneSettingsTab from '@/app/widgets/admin-panel-widget/tabs/GlobalRingtoneSettingsTab'
import RealmRingtoneSettingsTab from '@/app/widgets/admin-panel-widget/tabs/RealmRingtoneSettingsTab'
import UsersSettingsTab from '@/app/widgets/admin-panel-widget/tabs/UsersSettingsTab'
import LogosSettingsTab from '@/app/widgets/admin-panel-widget/tabs/LogosSettingsTab'
import LayoutTab from '@/app/widgets/admin-panel-widget/tabs/LayoutTab'

export default {
  name: 'AdminTabs',
  data: (vm) => ({
    tab: null,
    tabs: [
      { name: vm.$t('users_settings'), component: UsersSettingsTab, cypress: 'users_settings', permission: 'chs_can_change_admin_users' },
      { name: vm.$t('roles_settings'), component: RolesSettingsTab, cypress: 'roles_settings', permission: 'chs_can_change_admin_roles' },
      { name: vm.$t('layout_settings'), component: LayoutTab, cypress: 'layout_settings', permission: 'chs_can_change_admin_layout' },

      { name: vm.$t('global_settings'), component: GlobalSettingsTab, cypress: 'global_settings', permission: 'chs_can_change_admin_global_settings' },
      { name: vm.$t('account_settings'), component: AccountSettingsTab, cypress: 'account_settings', permission: 'chs_can_change_admin_account_settings' },
      { name: vm.$t('global_ringtone_settings'), component: GlobalRingtoneSettingsTab, cypress: 'global_ringtone_settings', permission: 'chs_can_change_admin_global_settings' },
      { name: vm.$t('realm_ringtone_settings'), component: RealmRingtoneSettingsTab, cypress: 'realm_ringtone_settings', permission: 'chs_can_change_admin_account_settings' },
      { name: vm.$t('logos_settings'), component: LogosSettingsTab, cypress: 'logos_settings', permission: 'chs_can_change_admin_logo' }

    ]
  }),

  methods: {
    changeTab (item) {
      this.name = item
    }
  }
}
</script>

<style scoped>

</style>
