import store from '@/store'

const localStorageService = (function () {
  const _service = this

  function _getService () {
    return _service
  }

  function _setUserTokens ({ userAccessToken, refreshToken }) {
    localStorage.setItem('user_access_token', userAccessToken)
    localStorage.setItem('refresh_token', refreshToken)
  }

  function _setAccountId ({ accountId }) {
    localStorage.setItem('account_id', accountId)
  }

  function _getAccountId () {
    return localStorage.getItem('account_id')
  }

  function _getUserAccessToken () {
    return localStorage.getItem('user_access_token')
  }

  function _getRefreshToken () {
    return localStorage.getItem('refresh_token')
  }

  function _clearLocalStorageOnLogout () {
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('user_access_token')
    localStorage.removeItem('userId')
    localStorage.removeItem('workspace_id')
  }

  function _getMapStyle () {
    return localStorage.getItem('map_style')
  }

  function _setMapStyle (val) {
    localStorage.setItem('map_style', val)
  }

  function _getRingingOutputId () {
    return localStorage.getItem('ringing_output_id')
  }

  function _setRingingOutputId (val) {
    if (Array.isArray(val)) {
      const device = val.filter(item => item.deviceId === this.getRingingOutputId())

      if (device.length > 0) {
        store.commit('settings/audioSettingsMutation', { type: 'ringingOutputDeviceId', data: this.getRingingOutputId() })
        localStorage.setItem('ringing_output_id', this.getRingingOutputId())
      } else {
        store.commit('settings/audioSettingsMutation', { type: 'ringingOutputDeviceId', data: 'default' })
        localStorage.setItem('ringing_output_id', 'default')
      }
    } else {
      store.commit('settings/audioSettingsMutation', { type: 'ringingOutputDeviceId', data: val })
      localStorage.setItem('ringing_output_id', val)
    }
  }

  function _getMicrophoneId () {
    return localStorage.getItem('microphone_id')
  }

  function _setMicrophoneId (val) {
    if (Array.isArray(val)) {
      const device = val.filter(item => item.deviceId === this.getMicrophoneId())

      if (device.length > 0) {
        store.commit('settings/audioSettingsMutation', { type: 'microphoneDeviceId', data: this.getMicrophoneId() })
        localStorage.setItem('microphone_id', this.getMicrophoneId())
      } else {
        store.commit('settings/audioSettingsMutation', { type: 'microphoneDeviceId', data: 'default' })
        localStorage.setItem('microphone_id', 'default')
      }
    } else {
      store.commit('settings/audioSettingsMutation', { type: 'microphoneDeviceId', data: val })
      localStorage.setItem('microphone_id', val)
    }
  }

  function _getCallerOutputId () {
    return localStorage.getItem('caller_output_id')
  }

  function _setCallerOutputId (val) {
    if (Array.isArray(val)) {
      const device = val.filter(item => item.deviceId === this.getCallerOutputId())

      if (device.length > 0) {
        store.commit('settings/audioSettingsMutation', { type: 'callerOutputDeviceId', data: this.getCallerOutputId() })
        localStorage.setItem('caller_output_id', this.getCallerOutputId())
      } else {
        store.commit('settings/audioSettingsMutation', { type: 'callerOutputDeviceId', data: 'default' })
        localStorage.setItem('caller_output_id', 'default')
      }
    } else {
      store.commit('settings/audioSettingsMutation', { type: 'callerOutputDeviceId', data: val })
      localStorage.setItem('caller_output_id', val)
    }
  }

  function _getItrOutputId () {
    return localStorage.getItem('itr_output_id')
  }

  function _setItrOutputId (val) {
    if (Array.isArray(val)) {
      const device = val.filter(item => item.deviceId === this.getItrOutputId())

      if (device.length > 0) {
        store.commit('settings/audioSettingsMutation', { type: 'itrOutputDeviceId', data: this.getItrOutputId() })
        localStorage.setItem('itr_output_id', this.getItrOutputId())
      } else {
        store.commit('settings/audioSettingsMutation', { type: 'itrOutputDeviceId', data: 'default' })
        localStorage.setItem('itr_output_id', 'default')
      }
    } else {
      store.commit('settings/audioSettingsMutation', { type: 'itrOutputDeviceId', data: val })
      localStorage.setItem('itr_output_id', val)
    }
  }

  function _getRingingVolume () {
    return parseFloat(localStorage.getItem('ringing_volume'))
  }

  function _setRingingVolume (val) {
    store.commit('settings/audioSettingsMutation', { type: 'ringingVolume', data: val })
    localStorage.setItem('ringing_volume', val.toString())
  }

  function _getCallerVolume () {
    return parseFloat(localStorage.getItem('caller_volume'))
  }

  function _setCallerVolume (val) {
    store.commit('settings/audioSettingsMutation', { type: 'callerVolume', data: val })
    localStorage.setItem('caller_volume', val.toString())
  }

  function _getItrVolume () {
    return parseFloat(localStorage.getItem('itr_volume'))
  }

  function _setItrVolume (val) {
    store.commit('settings/audioSettingsMutation', { type: 'itrVolume', data: val })
    localStorage.setItem('itr_volume', val.toString())
  }

  function _setThemeStyle (val) {
    localStorage.setItem('theme_style', val)
  }

  function _getThemeStyle () {
    const val = localStorage.getItem('theme_style')

    if (!val) return true

    return val.toLowerCase() === 'true'
  }

  return {
    getService: _getService,
    getMapStyle: _getMapStyle,
    setMapStyle: _setMapStyle,
    setUserTokens: _setUserTokens,
    getUserAccessToken: _getUserAccessToken,
    getRefreshToken: _getRefreshToken,
    clearLocalStorageOnLogout: _clearLocalStorageOnLogout,
    setAccountId: _setAccountId,
    getAccountId: _getAccountId,
    getRingingOutputId: _getRingingOutputId,
    setRingingOutputId: _setRingingOutputId,
    getMicrophoneId: _getMicrophoneId,
    setMicrophoneId: _setMicrophoneId,
    getCallerOutputId: _getCallerOutputId,
    setCallerOutputId: _setCallerOutputId,
    getItrOutputId: _getItrOutputId,
    setItrOutputId: _setItrOutputId,
    getRingingVolume: _getRingingVolume,
    setRingingVolume: _setRingingVolume,
    getCallerVolume: _getCallerVolume,
    setCallerVolume: _setCallerVolume,
    getItrVolume: _getItrVolume,
    setItrVolume: _setItrVolume,
    setThemeStyle: _setThemeStyle,
    getThemeStyle: _getThemeStyle
  }
})()

export default localStorageService
