export default function realmRingtoneApiModule (instance) {
  return {

    /**
     * Upload realm ringtone
     * @param realm
     * @param payload
     * @returns {Promise<*>}
     */
    upload: async (realm, payload) => {
      const resource = '/chs/ringtones/realm'

      const formData = new FormData()

      formData.append('realm', realm)
      formData.append('type_id', payload.type_id)
      formData.append('ringtone', payload.ringtone)

      const response = await instance.post(resource, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    },

    /**
     * Mark selected ringtone as default
     * @param realm
     * @param fileId
     * @returns {Promise<*>}
     */
    markAsDefault: async (realm, fileId) => {
      const resource = `/chs/ringtones/realm/${realm}/default/${fileId}`

      const response = await instance.put(resource)

      return response
    },

    /**
     * Delete ringtone
     * @param realm
     * @param fileId
     * @returns {Promise<*>}
     */
    deleteRingtone: async (realm, fileId) => {
      const resource = `/chs/ringtones/realm/${realm}/${fileId}`

      const response = await instance.delete(resource)

      return response
    },

    /**
     * Get all ringtones for realm
     * @param realm
     * @returns {Promise<*>}
     */
    getAll: async (realm) => {
      const resource = `/chs/ringtones/realm/${realm}`

      const response = await instance.get(resource)

      return response
    },

    /**
     * Get all default ringtones for realm
     * @returns {Promise<*>}
     */
    getAllDefault: async (realm) => {
      const resource = `/chs/ringtones/realm/${realm}/default`

      const response = await instance.get(resource)

      return response
    }

  }
}
