import store from '@/store'
export default {
  methods: {
    checkIfOffline () {
      window.addEventListener('offline', async (e) => {
        await store.dispatch('notifications/notificationSnackBarAction', {
          color: 'error',
          icon: 'mdi-connection',
          text: 'You are offline!',
          show: true,
          timeout: -1
        })
      })
    },
    checkIfOnline () {
      window.addEventListener('online', async (e) => {
        await store.dispatch('notifications/notificationSnackBarAction', {
          color: 'success',
          icon: 'mdi-connection',
          text: 'You are Online!',
          show: true,
          timeout: 5000
        })
      })
    }
  }
}
