import indexDBService from '@/modules/db/indexDB'
import agentsLocalCacherModule from '@/modules/cache/localCacher/modules/agents.localCacher.module'
import speedDialLocalCacherModule from '@/modules/cache/localCacher/modules/speedDial.localCacher.module'
import callHistoryLocalCacherModule from '@/modules/cache/localCacher/modules/callHistory.localCacher.module'
import contactBooksLocalCacherModule from '@/modules/cache/localCacher/modules/contactBooks.localCacher.module'
import abandonedCallsLocalCacherModule from '@/modules/cache/localCacher/modules/abandonedCalls.localCacher.module'

const localCacherService = {
  indexDB: {
    agents: agentsLocalCacherModule(indexDBService),
    speedDial: speedDialLocalCacherModule(indexDBService),
    callHistory: callHistoryLocalCacherModule(indexDBService),
    contactBooks: contactBooksLocalCacherModule(indexDBService),
    abandonedCalls: abandonedCallsLocalCacherModule(indexDBService),
    clearDB: function () {
      this.agents.clear()
      this.speedDial.clear()
      this.callHistory.clear()
      this.contactBooks.clear()
      this.abandonedCalls.clear()
    }
  }
}

export default localCacherService
