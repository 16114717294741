<template>

  <validation-observer ref="observer" >

    <v-form class="pt-4" @submit.prevent="onSubmit" autocomplete="off" novalidate :disabled="loading" >

      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="name" :name="$t('name')" rules="required">
            <v-text-field outlined  name="name" :label="$t('name')" v-model="form.name" :error-messages="errors" ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col>
          <validation-provider v-slot="{ errors }" vid="password" :name="$t('password')" :rules="{ required : !form.id}">
            <v-text-field type="password" outlined  name="password" autocomplete="new-password" :label="$t('password')" v-model="form.password" :error-messages="errors" ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col class="col-4">
          <validation-provider v-slot="{ errors }" vid="expire_after" :name="$t('expire_after')" rules="numeric">
            <v-text-field type="number" outlined  :placeholder="$t('never')" name="expire_after" :label="$t('expire_after_in_days')" v-model="form.expire_after" :error-messages="errors" ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col class="col-3">
          <validation-provider v-slot="{ errors }" vid="pass_upper" :name="$t('pass_upper')">
            <v-checkbox :label="$t('pass_upper')" v-model="form.pass_upper" :error-messages="errors"></v-checkbox>
          </validation-provider>
        </v-col>
        <v-col class="col-3">
          <validation-provider v-slot="{ errors }" vid="pass_lower" :name="$t('pass_lower')">
            <v-checkbox :label="$t('pass_lower')" v-model="form.pass_lower" :error-messages="errors"></v-checkbox>
          </validation-provider>
        </v-col>
        <v-col class="col-2">
          <validation-provider v-slot="{ errors }" vid="pass_number" :name="$t('pass_number')">
            <v-checkbox :label="$t('pass_number')" v-model="form.pass_number"  :error-messages="errors"></v-checkbox>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col>
          <validation-provider v-slot="{ errors }" vid="email" :name="$t('email')" rules="email|required">
            <v-text-field outlined  name="email" autocomplete="new-email" :label="$t('email')" v-model="form.email" :error-messages="errors"></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="roles" :name="$t('roles')" rules="required">

            <v-autocomplete outlined onabort="roles"
                            v-model="form.roles"
                            :items="roles"
                            :label="$t('roles')" item-value="name" item-text="name"
                            multiple small-chips
                            persistent-hint :error-messages="errors"
            ></v-autocomplete>

          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="permissions" :name="$t('permissions')" rules="">

            <v-autocomplete outlined  onabort="permissions"
                            v-model="form.permissions"
                            :items="permissions"
                            :label="$t('permissions')" item-value="name" item-text="name"
                            multiple small-chips
                            persistent-hint :error-messages="errors"

            ></v-autocomplete>

          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="sip_address" :name="$t('sip_address')" rules="email">
            <v-text-field outlined  name="sip_address" :label="$t('sip_address')" v-model="form.sip_address" :error-messages="errors"></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="mobile_phone" :name="$t('mobile_phone')" rules="required" >
            <v-text-field outlined  name="mobile_phone" :label="$t('mobile_phone')" v-model="form.mobile_phone" :error-messages="errors"></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()">
            {{ $t('cancel')}}
          </v-btn>
          <v-btn :disabled="loading" type="submit" color="red"  >
            {{ $t('save')}}
          </v-btn>
        </v-col>
      </v-row>

    </v-form>

  </validation-observer>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions, mapState } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'UserFormComponent',

  props: ['entity'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: () => ({
    loading: false,
    form: {
      id: null,
      name: '',
      email: '',
      password: null,
      sip_address: null,
      mobile_phone: null,
      roles: [],
      permissions: [],
      expire_after: '',
      pass_upper: false,
      pass_lower: false,
      pass_number: false
    }
  }),

  computed: {
    ...mapState({
      roles: state => state.admin.roles,
      permissions: state => state.admin.permissions
    })

  },

  mounted () {
    Object.assign(this.form, this.entity)
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        // when there is no new password set delete password key.
        if (this.form.password == null) {
          delete this.form.password
        }

        this.loading = true
        this.$emit('saving')

        if (this.form.id) {
          apiService.users.edit(this.form.id, this.form).then((response) => {
            this.afterSubmit(response)
          }).catch((error) => {
            if (error.response.data.message) {
              this.notificationSnackBarAction({
                show: true,
                color: 'error',
                text: error.response.data.message
              })
            }

            this.loading = false

            this.$refs.observer.setErrors(error.response.data.errors)
          })
        } else {
          apiService.users.create(this.form).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('user_created')
            })

            this.afterSubmit(response)
          }).catch((error) => {
            if (error.response.data.message) {
              this.notificationSnackBarAction({
                show: true,
                color: 'error',
                text: error.response.data.message
              })
            }

            this.loading = false

            this.$refs.observer.setErrors(error.response.data.errors)
          })
        }
      }
    },

    /**
     * Process roles & permission after submit
     * @param response
     * @returns {Promise<void>}
     */
    async afterSubmit (response) {
      const rolesAwait = apiService.roles.revokeRoles(response.data.data.id, this.roles.map((obj) => obj.name)).then((rolesResponse) => {
        apiService.roles.assignRoles(response.data.data.id, this.form.roles).then((rolesResponse) => {
        }).catch((error) => {
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: this.$t('something_went_wrong_please_try_again_later')
          })
          this.$refs.observer.setErrors(error.response.data.errors)
        })
      }).catch((error) => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })

        this.$refs.observer.setErrors(error.response.data.errors)
      })

      const permissionsAwait = apiService.permissions.revokePermissions(response.data.data.id, this.permissions.map((obj) => obj.name)).then((permissionsResponse) => {
        apiService.permissions.assignPermissions(response.data.data.id, this.form.permissions).then((permissionsResponse) => {
          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('user_updated')
          })

          this.$emit('onSubmit')
        }).catch((error) => {
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: this.$t('something_went_wrong_please_try_again_later')
          })

          this.$refs.observer.setErrors(error.response.data.errors)
        })
      }).catch((error) => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })

        this.$refs.observer.setErrors(error.response.data.errors)
      })

      Promise.all([rolesAwait, permissionsAwait]).then(() => {
        this.loading = false
      })
    },

    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>

<style scoped>

</style>
