import axios from 'axios'
import localStorageService from '@/util/local-storage-service'
import responseInterceptor from '@/modules/api/csp/response.interceptor'

const baseURL = process.env.VUE_APP_API_URL

const instance = axios.create({
  baseURL,
  headers: {
    accept: 'application/json'
  }
})

instance.interceptors.request.use(
  config => {
    if (!config.headers.Authorization) {
      const newConfig = {
        headers: {},
        ...config
      }
      const token = localStorageService.getUserAccessToken()
      newConfig.headers.Authorization = `Bearer ${token}`

      return newConfig
    }
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => response,
  responseInterceptor(instance)
)

export default instance
