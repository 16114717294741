var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"1000","scrollable":"","title":_vm.dialogTitle},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('UserForm',{key:_vm.formKey,attrs:{"entity":_vm.entity},on:{"onCancel":function($event){_vm.dialog = false},"onSubmit":_vm.onFormSubmit}})],1),_c('DialogShared',{attrs:{"max-width":"100","title":_vm.$t('import_users')},model:{value:(_vm.importUsersDialog),callback:function ($$v) {_vm.importUsersDialog=$$v},expression:"importUsersDialog"}},[_c('ImportUsersFormComponent',{key:_vm.formKey,on:{"onCancel":function($event){_vm.importUsersDialog = false},"onSubmit":_vm.onFormSubmit}})],1),_c('DialogShared',{attrs:{"max-width":"1000","title":_vm.$t('sip_address')},model:{value:(_vm.sipAddressDialog),callback:function ($$v) {_vm.sipAddressDialog=$$v},expression:"sipAddressDialog"}},[_c('UserSipAddressComponent',{key:_vm.formKey,attrs:{"entity":_vm.entity},on:{"onCancel":function($event){_vm.sipAddressDialog = false}}})],1),_c('DialogShared',{attrs:{"max-width":"1000","title":_vm.$t('account_settings')},model:{value:(_vm.accountSettingsDialog),callback:function ($$v) {_vm.accountSettingsDialog=$$v},expression:"accountSettingsDialog"}},[_c('UserAccountSettingList',{key:_vm.formKey,attrs:{"entity":_vm.entity},on:{"onCancel":function($event){_vm.accountSettingsDialog = false},"onSubmit":_vm.onFormSubmit}})],1),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onCreate}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('add_new')))])])],1),(_vm.$can('chs_can_import_users'))?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onImportUsers}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-database-arrow-left ")])]}}],null,false,3316129910)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('import_users')))])])],1):_vm._e()],1),_c('v-data-table',{staticClass:"transparent-background",attrs:{"dark":_vm.$vuetify.theme.dark,"items":_vm.users,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalUsers,"headers":_vm.headers,"footer-props":{itemsPerPageOptions : [5,10,15]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"clearable":"","label":_vm.$t('search')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadUsers.apply(null, arguments)},"click:clear":_vm.refreshUsersList},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"green","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEdit(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('edit')))])])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onSipAddressEdit(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-phone-plus ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('sip_address')))])])],1),_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onAccountSettings(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-cog-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('account_settings')))])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }