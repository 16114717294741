export default function speedDialLocalCacherModule (indexDB) {
  return {
    get: function (payload) {
      const data = indexDB.speedDial.get(payload)

      return data
    },
    updateTable: (data) => {
      indexDB.speedDial.insertMany(data)
    },
    delete: (id) => {
      indexDB.speedDial.delete(id)
    },
    edit: (id, data) => {
      indexDB.speedDial.edit(id, data)
    },

    clear () {
      indexDB.speedDial.clear()
    }
  }
}
