export default function abandonedCallsLocalCacherModule (indexDB) {
  return {
    get: function (payload) {
      const data = indexDB.abandonedCalls.get(payload)

      return data
    },
    updateTable: (data) => {
      indexDB.abandonedCalls.insertMany(data)
    },
    delete: (id) => {
      indexDB.abandonedCalls.delete(id)
    },
    clear () {
      indexDB.abandonedCalls.clear()
    }
  }
}
