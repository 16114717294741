import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'

export default function abandonedCallsDBModule (db) {
  db.version(1).stores({
    abandonedCalls: '++id, call_id'
  })

  return {
    insert: (data) => {
      db.abandonedCalls.add(data)
    },
    insertMany: (data) => {
      data.forEach(d => {
        db.abandonedCalls.add(d)
      })
    },
    get: (filter) => {
      return useObservable(
        liveQuery(async () => {
          return await db.abandonedCalls.reverse().toArray()
        })
      )
    },
    delete: (id) => {
      db.abandonedCalls.where({ call_id: id }).delete()
    },
    clear: () => {
      db.abandonedCalls.clear()
    }
  }
}
