<template>

  <v-card tile flat color="secondary_card">
    <v-card-text>
      <validation-observer ref="observer" >

        <v-form @submit.prevent="onSubmit" novalidate >

          <v-row>
            <v-col>
              <div v-if="entity.type == 'checkbox'">

                <v-switch dense flat inset v-model="form.value.active" :label="form.value.active ? $t('enabled') : $t('disabled')"   ></v-switch>
              </div>
              <div v-else>
                Unsupported Type {{ entity.type }} with value {{ entity.value }}
              </div>

              <div v-if="entity.value && entity.value.type == 'chip_input'">
                <v-combobox multiple
                            v-model="form.value.options"
                            :label="$t('options')"
                            append-icon
                            chips
                            deletable-chips
                            class="tag-input"
                            :search-input.sync="search"
                            @keyup.tab="updateTags"
                            @paste="updateTags">
                </v-combobox>
              </div>

              <div v-if="entity.name == 'automatic_location_update'">

                <validation-provider v-slot="{ errors }" vid="value" :name="$t('automatic_location_update_interval')" :rules="{ numeric: true, required: true, min_value: form.value.min, max_value: form.value.max}">
                  <v-text-field :persistent-hint="true" :hint="$t('value_should_be_between',{ min: form.value.min, max: form.value.max})"
                                v-model="form.value.value" type="number"
                                :label="$t('automatic_location_update_interval')"
                                :disabled="loading"
                                outlined
                                 :error-messages="errors"
                  ></v-text-field>
                </validation-provider>

              </div>

            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()">
                {{ $t('cancel')}}
              </v-btn>
              <v-btn :disabled="loading" type="submit" color="red"  >
                {{ $t('save')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

      </validation-observer>
    </v-card-text>
  </v-card>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'GlobalSettingsFormComponent',

  props: ['entity'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: () => ({
    loading: false,
    search: '',
    form: {
      value: {}
    }
  }),

  computed: {

  },

  mounted () {
    this.form.value = Object.assign({}, this.entity.value)
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    updateTags () {
      this.$nextTick(() => {
        this.form.value.options.push(...this.search.split(','))
        this.$nextTick(() => {
          this.search = ''
        })
      })
    },

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.loading = true

        apiService.globalSettings.edit(this.entity.id, this.form).then((response) => {
          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('global_setting_updated')
          })

          this.loading = false
          this.$emit('onSubmit')
        }).catch((error) => {
          this.$refs.observer.setErrors(error.response.data.errors)
          this.loading = false
        })
      }
    },
    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>
