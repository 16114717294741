<template>

  <div>

    <DialogShared v-if="form.layout_config" max-width="1000" :title="$t('widget_config')" v-model="editedWidget.dialog">
      <component @close="closeWidgetForm" @submit="submitWidgetForm" :widget="editedWidget" :is="editedWidget.componentName"></component>
    </DialogShared>

    <validation-observer ref="observer" >

      <v-form class="pt-4" @submit.prevent="onSubmit" novalidate >

        <v-row dense>
          <v-col>
            <validation-provider v-slot="{ errors }" name="name" rules="required">
              <v-text-field outlined name="name" :label="$t('name')" v-model="form.name" :error-messages="errors" ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col>
            <validation-provider v-slot="{ errors }" name="template" rules="required">
              <v-select :error-messages="errors" @change="onChangeTemplate"
                        v-model="form.layout_config.layout"
                        :items="defaultTemplatesGetter"
                        outlined
                        name="template"
                        item-text="label"
                        item-value="value"
                        :label="$t('dashboard_template')"
              >

              </v-select>

            </validation-provider>
          </v-col>
        </v-row>
        <v-row dense style="min-height: 600px" >
          <v-col cols="3" >
            <h3> {{ $t('widgets')}}</h3>

            <scroll-div height="530px" view-class="scroll-view" style="padding: 5px" >
              <v-list dense tile flat class="transparent-background" >
                <draggable :empty-insert-threshold="100"  v-model="widgets" handle=".handle" group="widgets">
                  <transition-group>
                    <v-list-item  class="layout_designer_list my-1"  dense v-for="(element,key) in widgets" :key="key+0">
                      <v-list-item-avatar class="pa-0 ma-0" >
                        <v-icon class="grabbable handle" >
                          mdi-drag
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content >
                        {{ element }}
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn v-if="isWidgetConfigurable(element)" icon @click="onWidgetConfigClick(element)" x-small class="mr-2" shaped>
                          <v-icon >mdi-cogs</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </transition-group>
                </draggable>
              </v-list>

            </scroll-div>

          </v-col>
          <v-col>
            <h3> {{ $t('slots')}}</h3>

            <div v-if="form.layout_config.layout == 'DashboardTemplate1Component'">

              <v-row>
                <v-col cols="2">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_1" :placeholder="form.layout_config.slots['slot_1']" ></slot-component>
                </v-col>
                <v-col cols="10">
                  <v-row>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_2" :placeholder="form.layout_config.slots['slot_2']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_3" :placeholder="form.layout_config.slots['slot_3']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_4" :placeholder="form.layout_config.slots['slot_4']" ></slot-component>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_5" :placeholder="form.layout_config.slots['slot_5']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_6" :placeholder="form.layout_config.slots['slot_6']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_7" :placeholder="form.layout_config.slots['slot_7']" ></slot-component>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

            </div>
            <div v-else-if="form.layout_config.layout == 'DashboardTemplate2Component'">

              <v-row>

                <v-col cols="10">
                  <v-row>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_2" :placeholder="form.layout_config.slots['slot_2']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_3" :placeholder="form.layout_config.slots['slot_3']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_4" :placeholder="form.layout_config.slots['slot_4']" ></slot-component>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_5" :placeholder="form.layout_config.slots['slot_5']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_6" :placeholder="form.layout_config.slots['slot_6']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_7" :placeholder="form.layout_config.slots['slot_7']" ></slot-component>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="2">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_1" :placeholder="form.layout_config.slots['slot_1']" ></slot-component>
                </v-col>
              </v-row>

            </div>
            <div v-else-if="form.layout_config.layout == 'DashboardTemplate3Component'">

              <v-row>
                <v-col cols="12">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_1" :placeholder="form.layout_config.slots['slot_1']" ></slot-component>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_2" :placeholder="form.layout_config.slots['slot_2']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_3" :placeholder="form.layout_config.slots['slot_3']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_4" :placeholder="form.layout_config.slots['slot_4']" ></slot-component>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_5" :placeholder="form.layout_config.slots['slot_5']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_6" :placeholder="form.layout_config.slots['slot_6']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_7" :placeholder="form.layout_config.slots['slot_7']" ></slot-component>
                    </v-col>
                  </v-row>
                </v-col>

              </v-row>

            </div>
            <div v-else-if="form.layout_config.layout == 'DashboardTemplate4Component'">
              <v-row>
                <v-col cols="3">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_1" :placeholder="form.layout_config.slots['slot_1']" ></slot-component>
                </v-col>
                <v-col cols="9" >
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_2" :placeholder="form.layout_config.slots['slot_2']" ></slot-component>
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="12">
                  <v-row>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_3" :placeholder="form.layout_config.slots['slot_3']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_4" :placeholder="form.layout_config.slots['slot_4']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_5" :placeholder="form.layout_config.slots['slot_5']" ></slot-component>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_6" :placeholder="form.layout_config.slots['slot_6']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_7" :placeholder="form.layout_config.slots['slot_7']" ></slot-component>
                    </v-col>
                    <v-col cols="4">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_8" :placeholder="form.layout_config.slots['slot_8']" ></slot-component>
                    </v-col>
                  </v-row>
                </v-col>

              </v-row>

            </div>
            <div v-else-if="form.layout_config.layout == 'DashboardTemplate5Component'">

              <v-row>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="12">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_1" :placeholder="form.layout_config.slots['slot_1']" ></slot-component>
                    </v-col>
                    <v-col cols="12">
                      <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_2" :placeholder="form.layout_config.slots['slot_2']" ></slot-component>
                    </v-col>
                  </v-row>

                </v-col>
                <v-col cols="2">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_3" :placeholder="form.layout_config.slots['slot_3']" ></slot-component>
                </v-col>
                <v-col cols="3">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_4" :placeholder="form.layout_config.slots['slot_4']" ></slot-component>
                </v-col>
                <v-col cols="3">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_5" :placeholder="form.layout_config.slots['slot_5']" ></slot-component>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_6" :placeholder="form.layout_config.slots['slot_6']" ></slot-component>
                </v-col>
                <v-col cols="4">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_7" :placeholder="form.layout_config.slots['slot_7']" ></slot-component>
                </v-col>
                <v-col cols="4">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_8" :placeholder="form.layout_config.slots['slot_8']" ></slot-component>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_9" :placeholder="form.layout_config.slots['slot_9']" ></slot-component>
                </v-col>
                <v-col cols="4">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_10" :placeholder="form.layout_config.slots['slot_10']" ></slot-component>
                </v-col>
                <v-col cols="4">
                  <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" slot-key="slot_11" :placeholder="form.layout_config.slots['slot_11']" ></slot-component>
                </v-col>
              </v-row>
            </div>
            <v-row v-else>
              <v-col  cols="4" v-for="(slot, slotKey) in form.layout_config.slots" :key="slotKey">
                <slot-component @onWidgetRemove="onWidgetRemove" @onSlotChange="onSlotChange" @onWidgetConfigClick="onWidgetConfigClick" :slot-key="slotKey" :placeholder="form.layout_config.slots[slotKey]" ></slot-component>
              </v-col>
            </v-row>

          </v-col>

        </v-row>

        <v-row dense>
          <v-col>
            <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()">
              {{ $t('cancel')}}
            </v-btn>
            <v-btn :disabled="loading" type="submit" color="red"  >
              {{ $t('save')}}
            </v-btn>
          </v-col>
        </v-row>

      </v-form>

    </validation-observer>
  </div>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DialogShared from '@/app/shared/dialog/DialogShared'
import '@/rules/validation.rules.js'

// Import Widget Forms
import WidgetFormCallControlComponent from '@/app/widgets/admin-panel-widget/components/layout/WidgetFormCallControlComponent'
import WidgetFormCallHistoryTabsComponent from '@/app/widgets/admin-panel-widget/components/layout/WidgetFormCallHistoryTabsComponent'
import WidgetFormEmergencyCallTabsComponent from '@/app/widgets/admin-panel-widget/components/layout/WidgetFormEmergencyCallTabsComponent'
import WidgetFormSpeedDialTabsComponent from '@/app/widgets/admin-panel-widget/components/layout/WidgetFormSpeedDialTabsComponent'
import SlotComponent from '@/app/widgets/admin-panel-widget/components/layout/SlotComponent'
export default {

  name: 'LayoutFormComponent',

  props: ['entity'],

  components: {
    SlotComponent,
    ValidationObserver,
    ValidationProvider,
    DialogShared,
    draggable,

    WidgetFormCallControlComponent,
    WidgetFormCallHistoryTabsComponent,
    WidgetFormEmergencyCallTabsComponent,
    WidgetFormSpeedDialTabsComponent

  },

  data: () => ({
    currentLayout: null,

    editedWidget: {
      key: null,
      dialog: false,
      componentName: null,
      form: null
    },

    loading: false,

    widgets: [],

    form: {
      id: null,
      name: '',
      layout_config: {}
    }
  }),

  computed: {
    ...mapGetters('layout', [
      'emptySlotsGetter',
      'defaultSlotsGetter',
      'defaultWidgetSettings',
      'defaultTemplatesGetter'
    ])

  },

  mounted () {
    const layoutAttr = {
      layout: this.defaultTemplatesGetter[0].value,
      slots: Object.assign({}, this.emptySlotsGetter),
      widgets: Object.assign({}, this.defaultWidgetSettings)
    }
    this.form.layout_config = layoutAttr

    if (this.entity) {
      Object.assign(this.form, this.entity)
      this.form.layout_config = {
        layout: this.entity.layout_config.layout,
        slots: { ...this.emptySlotsGetter, ...this.entity.layout_config.slots },
        widgets: { ...this.defaultWidgetSettings, ...this.entity.layout_config.widgets }
      }
    }
    const widgets = Object.assign(this.widgets, Object.keys(this.defaultWidgetSettings))

    const assignedWidgets = []

    for (const value of Object.values(this.form.layout_config.slots)) {
      assignedWidgets.push(...value)
    }
    this.widgets = widgets.filter((el) => {
      return !assignedWidgets.includes(el)
    })
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    /**
     * Check if widget component is configurable
     * @param widget
     * @returns {boolean}
     */
    isWidgetConfigurable (widget) {
      return this.defaultWidgetSettings[widget].configurable
    },

    onWidgetRemove (widget) {
      this.widgets.push(widget.element)

      this.form.layout_config.slots[widget.slotKey] = this.form.layout_config.slots[widget.slotKey].filter((element) => {
        return element !== widget.element
      })
    },

    closeWidgetForm () {
      this.editedWidget.form = null
      this.editedWidget.dialog = false
      this.editedWidget.componentName = null
      this.editedWidget.key = null
    },
    submitWidgetForm (widget) {
      this.form.layout_config.widgets[widget.key] = widget.form
      this.closeWidgetForm()
    },

    onSlotChange (val) {
      this.form.layout_config.slots[val.slotKey] = val.element
    },

    /**
     * Open widget configuration in dialog
     * @param element
     */
    onWidgetConfigClick (element) {
      this.editedWidget.componentName = `WidgetForm${element}`
      this.editedWidget.key = element
      this.editedWidget.form = this.form.layout_config.widgets[element]
      this.editedWidget.dialog = true
    },

    onChangeTemplate () {
      // TODO Reset widget setup on layout change
    },

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.loading = true

        if (this.form.id) {
          apiService.layouts.edit(this.form.id, this.form).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('layout_updated')
            })

            this.loading = false

            this.$emit('onSubmit')
          }).catch((error) => {
            this.$refs.observer.setErrors(error.response.data.errors)
            this.loading = false
          })
        } else {
          apiService.layouts.create(this.form).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('layout_created')
            })
            this.loading = false
            this.$emit('onSubmit')
          }).catch((error) => {
            this.$refs.observer.setErrors(error.response.data.errors)
            this.loading = false
          })
        }
      }
    },

    onCancel () {
      this.$emit('onCancel')
    }
  }

}
</script>

<style scoped lang="scss">

.box{
  display: flex;
  > * {
    flex: 1 1 0;
  }

}
</style>
