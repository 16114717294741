<template>

  <validation-observer ref="observer" >
    <v-card :loading="loading" tile flat color="secondary_card" >
      <v-card-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="!!!$can('chs_can_add_connection_settings')" v-on="on" cypress="connection_settings_add_proxy" @click="addRow" icon>
              <v-icon color="success">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('add_new') }} </span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form :disabled="loading" ref="form" novalidate>

              <template v-for="(item, index) in settingsProxyGetter" >
                <v-row :key="item.id" >
                <v-col align-self="center" cols="1">
                  <v-btn :disabled="!!!$can('chs_can_delete_connection_settings')" cypress="connection_settings_remove_proxy" @click="removeRow(index, item.id)" v-if="index !== settingsProxyGetter.length - 1" icon>
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="7">

                  <validation-provider v-slot="{ errors }" :name="$t('proxy')" rules="required|proxy">
                    <v-text-field
                      :label="$t('proxy') + '*'"  :error-messages="errors"
                      cypress="connection_settings_proxy"
                      required
                      dark
                      clearable
                      counter="200"
                      hint="eg:some-proxy-server.com:port"
                      :value="item.proxy"
                      @input="updateProxy($event, index, 'proxy')"
                    ></v-text-field>
                  </validation-provider>

                </v-col>
                <v-col cols="2">

                  <validation-provider v-slot="{ errors }" :name="$t('priority')" rules="required">
                    <v-combobox
                      cypress="connection_settings_priority"  :error-messages="errors"
                      :value="item.priority"
                      @input="updateProxy($event, index, 'priority')"
                      :items="priority"
                      :label="$t('priority')"
                    ></v-combobox>
                  </validation-provider>
                </v-col>
                <v-col align-self="center" cols="2">
                  <v-btn cypress="connection_settings_check" @click="checkSocket(settingsProxyGetter[index].proxy)" small color="success">
                    <span class="caption">{{ $t('check') }}</span>
                  </v-btn>
                </v-col>
                </v-row>
              </template>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container class="text-right">
          <v-btn color="error" :disabled="!!!$can('chs_can_edit_connection_settings')" cypress="connection_settings_save"
                  :loading="loading"
                  @click="addConnection()"
          >
            {{ $t('save') }}
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import apiService from '@/modules/api/csp'
import { mapActions, mapGetters } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/rules/validation.rules.js'

export default {
  name: 'ConnectionSettingsTab',
  data: () => ({
    loading: false,
    autoRegister: false,
    priority: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  }),

  components: {
    ValidationObserver,
    ValidationProvider
  },

  computed: {
    ...mapGetters('global', [
      'authUserGetter'
    ]),

    ...mapGetters('settings', [
      'settingsProxyGetter'
    ])
  },
  methods: {
    ...mapActions('settings', [
      'settingsProxyAction',
      'addSettingsProxyAction',
      'updateSettingsProxyAction',
      'deleteSettingsProxyAction'
    ]),
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),
    updateProxy (value, index, type) {
      const data = {
        value: value,
        index: index,
        type: type
      }
      this.updateSettingsProxyAction(data)
    },
    addRow () {
      this.addSettingsProxyAction({
        proxy: null,
        priority: null,
        id: null,
        user_id: this.authUserGetter.id
      })
    },
    async removeRow (index, id) {
      this.loading = true
      this.deleteSettingsProxyAction(index)
      await apiService.connectionSettings.deleteConnectionSetting(id)
        .finally(() => { this.loading = false })
    },
    async addConnection () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.loading = true
        this.settingsProxyGetter.forEach(item => {
          apiService.connectionSettings.saveConnectionSetting(item)
            .then(async () => {
              this.loading = true
              await this.settingsProxyAction().then(data => {})
                .finally(() => { this.loading = false })
            }).finally(() => { this.loading = false })
        })
      }
    },
    checkSocket (url) {
      const socket = new WebSocket(`wss://${url}`)

      if (socket.readyState) {
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: this.$t('socket_connected_successfully')
        })
      } else {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('socket_connection_failed')
        })
      }
    }
  },
  async mounted () {

  }
}
</script>

<style scoped>

</style>
