<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn title="Admin"
             @click="setAdminDialog()"
             icon
             color="grey_light"
             text
             v-on="on"
      >
        <v-icon>mdi-account-group</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('admin_panel') }}</span>

    <dialog-shared max-width="1200" :title="$t('admin_panel')" v-model="adminDialogEnabled">
      <admin-panel></admin-panel>
    </dialog-shared>

  </v-tooltip>
</template>

<script>
import DialogShared from '@/app/shared/dialog/DialogShared'
import AdminPanel from '@/app/widgets/admin-panel-widget/components/AdminPanelComponent'
export default {
  name: 'AdminPanelButton',
  components: { AdminPanel, DialogShared },

  data: () => ({
    adminDialogEnabled: false
  }),

  methods: {
    setAdminDialog () {
      this.adminDialogEnabled = !this.adminDialogEnabled
    }
  }
}
</script>

<style scoped>

</style>
