import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'

export default function callHistoryDBModule (db) {
  db.version(1).stores({
    callHistory: '++id'
  })

  return {
    insert: (data) => {
      db.callHistory.add(data)
    },
    insertMany: (data) => {
      data.forEach(d => {
        db.callHistory.add(d)
      })
    },
    get: (filter) => {
      return useObservable(
        liveQuery(async () => {
          return await db.callHistory.reverse().toArray()
        })
      )
    },
    clear: () => {
      db.callHistory.clear()
    }
  }
}
