export default function adrApiModule (instance) {
  return {

    get: async (resource) => {
      const data = {
        method: 'get',
        url: resource,
        headers: {
          accept: 'application/xml'
        }
      }

      return await instance(data)
    }
  }
}
