import { format, utcToZonedTime } from 'date-fns-tz'

const timeHelper = {

  convertUnixToGeorgianSeconds (unix) {
    return unix + 62167219200
  },

  convertSecondsToHms (seconds) {
    return new Date(seconds * 1000).toISOString().substr(11, 8)
  },

  /**
   * Date format using timezone from browser
   * @param value
   * @returns {string}
   */
  dateFormatter (value) {
    const zonedDate = utcToZonedTime(value, Intl.DateTimeFormat().resolvedOptions().timeZone)
    return format(zonedDate, 'MM/dd/yyyy h:mma')
  }

}

export default timeHelper
