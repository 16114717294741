export default function contactBookApiModule (instance) {
  return {

    /**
     * Get contacts from contact book
     * @param data
     * @returns {Promise<*>}
     */
    get: async (data) => {
      const resource = '/contact-book'

      const response = await instance.get(resource, {
        params: data
      })

      return response
    },

    /**
     * Search contact book
     * @param data
     * @returns {Promise<*>}
     */
    search: async (data) => {
      const resource = '/contact-book/search'

      const response = await instance.get(resource, {
        params: data
      })

      return response
    },

    /**
     * Create contact book by file
     * @param data
     * @returns {Promise<*>}
     */
    createByFile: async (data) => {
      const resource = '/contact-book/create-by-file'

      const formData = new FormData()
      formData.append('file', data.file)

      const response = await instance.post(resource, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return response
    },

    /**
     * Create contact book entry
     * @param data
     * @returns {Promise<*>}
     */
    create: async (data) => {
      const resource = '/contact-book'

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Edit contact book entry
     * @param id
     * @param data
     * @returns {Promise<*>}
     */
    edit: async (id, data) => {
      const resource = `/contact-book/${id}`

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Delete contact book entry
     * @param id
     * @returns {Promise<*>}
     */
    delete: async (id) => {
      const resource = `/contact-book/${id}`

      const response = await instance.delete(resource)

      return response
    }

  }
}
