import * as JsSIP from '@/modules/jssip/third-party/lib/JsSIP'
import SipSessionPlugin from '@/modules/jssip/session.module'

export default class Sip {
    proxy;
    sipAddress;
    password;
    displayName;
    register;
    remoteAudio;
    username;
    domain;
    JsSIP;
    UA;
    attempts;
    session = [];
    registrationAttempts = 0;
    answeredCallSession = null;
    ringing = false;
    audio = null;
    incomingSessionCount = 0;
    connecting = false;
    connected = false;
    registering = false;
    registered = false;
    hasAnsweredCall = false;

    constructor () {
      this.JsSIP = JsSIP
      process.env.VUE_APP_ENV === 'development' ? JsSIP.debug.enable('JsSIP:*') : JsSIP.debug.disable()
    }

    /**
     * SIP Connect
     *
     * @param { [] } proxy
     * @param { string } sipAddress
     * @param { string } password
     * @param { string } displayName
     * @param { boolean } register
     * @param { string } remoteAudio
     *
     * @return { boolean } response
     */
    connect = async ({ proxy, sipAddress, password, displayName, register, remoteAudio }) => {
      this.proxy = proxy
      this.sipAddress = sipAddress.toLowerCase()
      this.password = password
      this.displayName = displayName
      this.register = register
      this.remoteAudio = remoteAudio
      this.username = sipAddress.split('@')[0]
      this.domain = sipAddress.split('@')[1]

      const config = this.getConfig()

      this.UA = new JsSIP.UA(config)

      this.triggerUAEvents()

      this.UA.start()
    };

    registerUA = () => {
      this.UA.register()
    }

    unregisterUA = () => {
      this.UA.unregister()
    }

    disconnect = () => {
      this.UA.stop()
    };

    triggerUAEvents = () => {
      // Trigger connecting event
      this.UA.on('connecting', async e => {
        this.connecting = true
        this.attempts = e.attempts
        const event = new Event('connecting')
        event.eventData = e
        document.dispatchEvent(event)
      })
      // Trigger connected event
      this.UA.on('connected', async e => {
        this.connecting = false
        this.connected = true
        const event = new Event('connected')
        event.eventData = e
        document.dispatchEvent(event)
      })
      // Trigger disconnected event
      this.UA.on('disconnected', async e => {
        this.connected = false
        this.connecting = false
        // this.UA.terminateSessions();
        if (this.attempts < 3) {
          console.log('less 3', this.attempts)
          const event = new Event('disconnected')
          event.eventData = e
          event.attempt = this.attempts
          document.dispatchEvent(event)
        } else {
          console.log('more 3', this.attempts)
          const event = new Event('connectionFailed')
          event.eventData = e
          document.dispatchEvent(event)
          this.disconnect()
        }
      })
      // Trigger registered event
      this.UA.on('registered', async e => {
        this.registered = true
        this.registrationAttempts = 0
        const event = new Event('registered')
        event.eventData = e
        document.dispatchEvent(event)
      })
      // Trigger unregistered event
      this.UA.on('unregistered', async e => {
        // Set registration property to false
        this.registered = false
        this.registrationAttempts = 0
        const event = new Event('unregistered')
        event.eventData = e
        document.dispatchEvent(event)
      })
      // Trigger registrationFailed event
      this.UA.on('registrationFailed', async e => {
        // Try to register again
        if (this.registrationAttempts < 1) {
          this.UA.register()
          this.registrationAttempts++
        } else {
          setTimeout(() => {
            this.registrationAttempts = 0
            this.UA.register()
          }, 10000)
        }
        const event = new Event('registrationFailed')
        event.eventData = e
        event.attempt = this.registrationAttempts
        document.dispatchEvent(event)
      })
      // Trigger newRTCSession event
      this.UA.on('newRTCSession', async e => {
        const index = this.session.push(e.session) - 1
        const sipSession = new SipSessionPlugin(this.session[index])
        sipSession.triggerEvents()
      })
      // Trigger newMessage event
      this.UA.on('newMessage', e => {
        const event = new Event('newMessage')
        event.eventData = e
        document.dispatchEvent(event)
      })
      // Trigger sipEvent event
      this.UA.on('sipEvent', e => {
        const event = new Event('sipEvent')
        event.eventData = e
        document.dispatchEvent(event)
      })
    };

    call = (target, options) => {
      return this.UA.call(target, options)
    };

    conference = (target, options) => {
      // options.extraHeaders = [ `Contact: <sip:${target}@${this.#domain};transport=ws>` ];

      const to = `sip:${target}@${this.domain}`

      return this.UA.call(to, options)
    };

    getConfig = () => {
      const wssUrl = []

      this.proxy.forEach((item, index) => {
        wssUrl[index] =
                {
                  socket: new JsSIP.WebSocketInterface(`wss://${item.proxy}`),
                  weight: item.priority
                }

        wssUrl[index].socket.via_transport = 'ws'
      })

      return {
        sockets: wssUrl,
        authorization_user: this.username,
        uri: `sip:${this.sipAddress}`,
        password: this.password,
        display_name: this.displayName,
        realm: this.domain,
        contact_uri: `sip:${this.sipAddress};transport=ws`,
        register: false,
        register_expires: 600,
        user_agent: `NGA CHS ${process.env.VUE_APP_VERSION}`,
        session_timers: true,
        session_timers_refresh_method: 'invite',
        connection_recovery_min_interval: 5,
        connection_recovery_max_interval: 10
      }
    };

    setIncomingSessionCount (val) {
      if (val === '-' && this.incomingSessionCount > 0) {
        this.incomingSessionCount--
      } else if (val === '+') {
        this.incomingSessionCount++
      }
    }

    isAdminCall = (request) => {
      return !(request.headers['X-Cav-Urn-Service'] && request.headers['X-Cav-Urn-Service'][0]?.raw?.trim() === 'urn:service:sos')
    }

    isConferenceCall = (request) => {
      return !!(request?.headers?.Contact?.[0]?.raw?.match(/isfocus/))
    }

    sendCustomRequest = (method, options) => {
      this.answeredCallSession.sendRequest(method, {
        extraHeaders: options.extraHeaders,
        body: options.body
      })
    }
}
