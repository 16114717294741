export const ringingGetter = state => {
  return state.ringingState
}

export const testRingingGetter = state => {
  return state.testRingingState
}

export const testCallerGetter = state => {
  return state.testCallerState
}
