export const dateTimeMutator = (state, payload) => {
  state.dateTimeState = payload
}

export const esrkNumberMutator = (state, payload) => {
  state.esrkNumberState = payload
}

export const nameMutator = (state, payload) => {
  state.nameState = payload
}
