<template>

    <v-list dense class="transparent-background layout_designer_list pa-1" >

      <v-list-item dense>
        <b>{{ slotKey }}</b>
      </v-list-item>
      <draggable :empty-insert-threshold="100" v-model="element" handle=".handle" group="widgets">
        <transition-group>
          <v-list-item class="layout_designer_list pa-0"  dense v-for="(element,key) in element" :key="key+0">

            <v-list-item-avatar class="pa-0 ma-0" >
              <v-icon class="grabbable handle" >
                mdi-drag
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content >
              {{ element }}
            </v-list-item-content>
            <v-list-item-action >
              <div class="d-flex justify-center align-center">
                <v-btn @click="onWidgetRemove(element)" icon x-small class="mr-1" shaped>
                  <v-icon >mdi-close</v-icon>
                </v-btn>
                <v-btn v-if="isWidgetConfigurable(element)" icon @click="onWidgetConfigClick(element)" x-small class="mr-2" shaped>
                  <v-icon >mdi-cogs</v-icon>
                </v-btn>
              </div>

            </v-list-item-action>
          </v-list-item>
        </transition-group>
      </draggable>
    </v-list>

</template>

<script>

import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'

export default {
  name: 'SlotComponent',

  props: ['placeholder', 'slotKey'],

  components: {
    draggable
  },

  data: () => ({
    element: []
  }),

  computed: {
    ...mapGetters('layout', [
      'emptySlotsGetter',
      'defaultSlotsGetter',
      'defaultWidgetSettings',
      'defaultTemplatesGetter'
    ])
  },

  watch: {
    placeholder: {
      deep: true,
      handler (_new, _old) {
        this.element = _new
      }
    },
    element: {
      deep: true,
      handler (_new, _old) {
        this.onSlotChange({
          slotKey: this.slotKey,
          element: _new
        }
        )
      }
    }
  },

  mounted () {
    this.element = this.placeholder
  },

  methods: {
    onSlotChange (element) {
      this.$emit('onSlotChange', element)
    },
    isWidgetConfigurable (widget) {
      return this.defaultWidgetSettings[widget].configurable
    },
    onWidgetRemove (element) {
      this.$emit('onWidgetRemove', {
        slotKey: this.slotKey,
        element: element
      })
    },
    onWidgetConfigClick (element) {
      this.$emit('onWidgetConfigClick', element)
    }
  }

}
</script>

<style scoped>

</style>
