export default function userApiModule (instance) {
  return {

    /**
     * Get logged user data
     * @returns {*}
     */
    async get () {
      return await instance.get('/auth/me')
    },

    /**
     * Authenticate user
     * @param payload
     * @returns {*}
     */
    async login (payload) {
      return await instance.post('auth/login', payload)
    },

    /**
     * Logout user from api
     * @returns {*}
     */
    async logout () {
      return await instance.post('auth/logout')
    },

    /**
     * Change password api
     * @param data
     * @returns {Promise<*>}
     */
    async changePassword (data) {
      return await instance.post('auth/reset-password', data)
    }
  }
}
