export default function sipApiModule (instance) {
  return {

    /**
     * Get realms
     * @returns {Promise<*>}
     */
    getRealms: async () => {
      const resource = '/sip-address/realms'

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Get user realms
     * @param userId User Id
     * @returns {Promise<*>}
     */
    getUserRealms: async (userId) => {
      const resource = `/sip-address/realms/${userId}`

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Returns default sip credentials.
     *
     * @param { number } userId
     *
     * @return { Promise } response
     */
    getAllSipAddresses: async userId => {
      const resource = `/sip-address/get/user/${userId}`

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Returns default sip credentials.
     *
     * @return { Promise } response
     */
    getDefaultSipAddresses: async () => {
      const resource = '/sip-address/get-default'

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Returns default sip credentials.
     *
     * @param { Object } data
     *
     * @return { Promise } response
     */
    addSipAddress: async data => {
      const resource = '/sip-address/create'

      const response = await instance.post(resource, data)

      return response.data
    },

    updateDefaultSipAddress: async data => {
      const resource = `/sip-address/update/default/${data.id}`

      const response = await instance.patch(resource, data)

      return response.data
    },

    /**
     * Update sip address
     * @param data
     * @returns {Promise<*>}
     */
    updateSipAddress: async data => {
      const resource = `/sip-address/update/${data.id}`

      const response = await instance.patch(resource, data)

      return response.data
    },

    /**
     * Remove sip address
     * @param data
     * @returns {Promise<*>}
     */
    removeSipAddress: async data => {
      const resource = `/sip-address/delete/${data}`

      const response = await instance.delete(resource)

      return response.data
    }
  }
}
